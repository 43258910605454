import { log } from "./logger";

import { v4 as uuid } from "uuid";

import { TwilsockError } from "./error/twilsockerror";
import { TwilsockReplyError } from "./error/twilsockreplyerror";
import { Configuration } from "./configuration";
import { Parser } from "./parser";

import * as Messages from "./protocol/messages";

import { Metadata } from "./metadata";

const REQUEST_TIMEOUT = 30000;

function isHttpSuccess(code: number): boolean {
  return code >= 200 && code < 300;
}

/**
 * Makes sure that body is properly stringified
 */
function preparePayload(payload): string {
  switch (typeof payload) {
    case "undefined":
      return "";
    case "object":
      return JSON.stringify(payload);
    default:
      return payload;
  }
}

interface PacketRequest {
  timeout: ReturnType<typeof setTimeout>;
  reject: (reason?: TwilsockReplyError | TwilsockError) => void;
  resolve: (value?: unknown | PromiseLike<unknown>) => void;
}

interface PacketResponse {
  id: string;
  header: {
    continuation_token: string;
    continuation_token_status?: ContinuationTokenStatus;
    offline_storage: string;
    init_registrations: string;
    debug_info: string;
    capabilities: string[];
    http_status: {
      status: string;
      code: number;
      errorCode?: string;
    };
    http_headers: Headers;
  };
  body: Context;
}

import type { Headers, Header, Context } from "./protocol/protocol";
import { Channel } from "./interfaces/channel";
import { ContinuationTokenStatus } from "./protocol/messages/initReply";
import { ReplyType } from "./protocol/messages/reply";

class PacketInterface {
  private readonly config: Configuration;
  private readonly activeRequests: Map<string, PacketRequest>;
  private readonly channel: Channel;

  constructor(channel: Channel, config: Configuration) {
    this.config = config;
    this.activeRequests = new Map<string, PacketRequest>();

    this.channel = channel;
    this.channel.on("reply", (reply) => this.processReply(reply));
    this.channel.on("disconnected", () => {
      this.activeRequests.forEach((descriptor) => {
        clearTimeout(descriptor.timeout);
        descriptor.reject(new TwilsockError("disconnected"));
      });
      this.activeRequests.clear();
    });
  }

  public get isConnected(): boolean {
    return this.channel.isConnected;
  }

  public processReply(reply: ReplyType): void {
    const request = this.activeRequests.get(reply.id);
    if (request) {
      clearTimeout(request.timeout);
      this.activeRequests.delete(reply.id);

      if (!isHttpSuccess(reply.status.code)) {
        request.reject(
          new TwilsockReplyError(
            "Transport failure: " + reply.status.status,
            reply
          )
        );
        log.trace("message rejected");
      } else {
        request.resolve(reply);
      }
    }
  }

  private storeRequest(id: string, resolve, reject): void {
    const requestDescriptor = {
      resolve: resolve,
      reject: reject,
      timeout: setTimeout(() => {
        log.trace("request", id, "is timed out");
        reject(new TwilsockError("Twilsock: request timeout: " + id));
      }, REQUEST_TIMEOUT) as ReturnType<typeof setTimeout>,
    };
    this.activeRequests.set(id, requestDescriptor);
  }

  public shutdown(): void {
    this.activeRequests.forEach((descriptor) => {
      clearTimeout(descriptor.timeout);
      descriptor.reject(
        new TwilsockError("Twilsock: request cancelled by user")
      );
    });
    this.activeRequests.clear();
  }

  public async sendInit(): Promise<Messages.InitReply> {
    log.trace("sendInit");

    const metadata = Metadata.getMetadata(this.config);
    const message = new Messages.Init(
      this.config.token,
      this.config.continuationToken,
      metadata,
      this.config.initRegistrations,
      this.config.tweaks
    );

    const response = await this.sendWithReply(message);
    return new Messages.InitReply(
      response.id,
      response.header.continuation_token,
      new Set<string>(response.header.capabilities),
      response.header.continuation_token_status,
      response.header.offline_storage,
      response.header.init_registrations,
      response.header.debug_info
    );
  }

  public sendClose(): void {
    const message = new Messages.Close();
    //@todo send telemetry AnyEventsIncludingUnfinished
    this.send(message);
  }

  public sendWithReply(
    header: Partial<Header>,
    payload?: Context
  ): Promise<PacketResponse> {
    return new Promise((resolve, reject) => {
      const id = this.send(header, payload);
      this.storeRequest(id, resolve, reject);
    });
  }

  public send(header: Partial<Header>, payload?: Context): string {
    header.id = header.id || `TM${uuid()}`;
    const message = Parser.createPacket(header, preparePayload(payload));

    try {
      this.channel.send(message);
      return header.id;
    } catch (e) {
      log.debug("failed to send ", header, e);
      log.trace(e.stack);
      throw e;
    }
  }
}

export { Channel, PacketResponse, PacketInterface };
