import { MessageType } from "../protocol";
import { AbstractMessage } from "./abstractmessage";

class TelemetryEvent {
  constructor(
    readonly start: number, // relative to event send time
    readonly end: number, // relative to event send time
    readonly title: string,
    readonly details: string,
    readonly id?: string, // optional, default will be random assigned by backend
    readonly type?: string
  ) {} // optional, default will be "SDK" assigned by backend
}

class Telemetry extends AbstractMessage {
  public readonly method = "telemetry.v1" as MessageType;
  public events: TelemetryEvent[];

  constructor(events: TelemetryEvent[]) {
    super();
    this.events = events;
  }
}

export { Telemetry, TelemetryEvent };
