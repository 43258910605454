import { Paginator } from "./interfaces/paginator";

interface PaginatorState<T> {
  source: (token: string) => Promise<RestPaginator<T>>;
  nextToken: string;
  prevToken: string;
  items: T[];
}

/**
 * Pagination helper class.
 */
class RestPaginator<T> implements Paginator<T> {
  private state: PaginatorState<T>;

  /**
   * Indicates the existence of the next page.
   */
  public get hasNextPage(): boolean {
    return !!this.state.nextToken;
  }

  /**
   * Indicates the existence of the previous page
   */
  public get hasPrevPage(): boolean {
    return !!this.state.prevToken;
  }

  /**
   * Array of elements on the current page.
   */
  public get items(): T[] {
    return this.state.items;
  }

  /**
   * @internal
   */
  constructor(items, source, prevToken, nextToken) {
    this.state = {
      prevToken,
      nextToken,
      source,
      items,
    };
  }

  /**
   * Request the next page. Does not modify the existing object.
   */
  nextPage(): Promise<RestPaginator<T>> {
    return this.hasNextPage
      ? this.state.source(this.state.nextToken)
      : Promise.reject(new Error("No next page"));
  }

  /**
   * Request the previous page. Does not modify the existing object.
   */
  prevPage(): Promise<RestPaginator<T>> {
    return this.hasPrevPage
      ? this.state.source(this.state.prevToken)
      : Promise.reject(new Error("No previous page"));
  }
}

export { RestPaginator };
