class TransportError extends Error {
  public readonly code: number;
  public readonly body: any;
  public readonly status: string;
  public readonly headers: any;

  constructor(
    message: string,
    code: number,
    body: any,
    status: string,
    headers: any
  ) {
    super(message);
    this.code = code;
    this.body = body;
    this.status = status;
    this.headers = headers;
  }
}

export { TransportError };
