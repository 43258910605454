import { log } from "../logger";
import { Configuration } from "../configuration";
import { PacketInterface } from "../packetinterface";
import { TwilsockError } from "../error/twilsockerror";
import { TwilsockUpstreamError } from "../error/twilsockupstreamerror";
import { Headers, Result } from "../interfaces/transport";
import type {
  Address,
  Headers as ProtocolHeaders,
  Context,
  Request as ProtocolRequest,
} from "../protocol/protocol";
import * as Messages from "../protocol/messages";
import { TransportUnavailableError } from "../error/transportunavailableerror";
import { TwilsockChannel } from "../twilsock";
import { TwilsockReplyError } from "../error/twilsockreplyerror";
const REQUEST_TIMEOUT = 20000;

type MessageType = {
  to: Address;
  headers: ProtocolHeaders;
  body: Context;
  grant?: string;
};

function isHttpSuccess(code: number): boolean {
  return code >= 200 && code < 300;
}

function isHttpReply(packet): boolean {
  return packet && packet.header && packet.header.http_status;
}

interface Request {
  message: MessageType;
  timeout: ReturnType<typeof setTimeout>;
  reject: (reason?: TwilsockReplyError | TwilsockError) => void;
  resolve: (value: Result<Context> | PromiseLike<Result<Context>>) => void;
  alreadyRejected: boolean;
}

function parseUri(uri: string) {
  const match = uri.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)(\/[^?#]*)(\?[^#]*|)(#.*|)$/
  );
  if (match) {
    const uriStruct = {
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
      params: {},
    };

    if (uriStruct.search.length > 0) {
      const paramsString = uriStruct.search.substring(1);
      uriStruct.params = paramsString
        .split("&")
        .map((el) => el.split("="))
        .reduce((prev, curr) => {
          if (!prev.hasOwnProperty(curr[0])) {
            prev[curr[0]] = curr[1];
          } else if (Array.isArray(prev[curr[0]])) {
            prev[curr[0]].push(curr[1]);
          } else {
            prev[curr[0]] = [prev[curr[0]], curr[1]];
          }
          return prev;
        }, {});
    }
    return uriStruct;
  }
  throw new TwilsockError("Incorrect URI: " + uri);
}

function twilsockAddress(method: string, uri: string): Address {
  const parsedUri = parseUri(uri);
  const to = {
    method: method,
    host: parsedUri.host,
    path: parsedUri.pathname,
  } as Address;
  if (parsedUri.params) {
    to.params = parsedUri.params;
  }
  return to;
}

function twilsockParams(
  method: string,
  uri: string,
  headers: Headers,
  body?,
  grant?: string
) {
  return {
    to: twilsockAddress(method, uri),
    headers: headers,
    body: body,
    grant: grant,
  };
}

class Upstream {
  private readonly config: Configuration;
  private readonly transport: PacketInterface;
  private readonly pendingMessages: Request[];
  private readonly twilsock: TwilsockChannel;

  constructor(
    transport: PacketInterface,
    twilsock: TwilsockChannel,
    config: Configuration
  ) {
    this.config = config;
    this.transport = transport;
    this.pendingMessages = [];
    this.twilsock = twilsock;
  }

  public saveMessage(message: MessageType): Promise<Result<Context>> {
    return new Promise<Result<Context>>((resolve, reject) => {
      const requestDescriptor = {
        message,
        resolve,
        reject,
        alreadyRejected: false,
        timeout: setTimeout(() => {
          log.debug("request is timed out");
          reject(
            new TwilsockError(
              `request '${message.to.method}' to '${message.to.host}' timed out`
            )
          );
          requestDescriptor.alreadyRejected = true;
        }, REQUEST_TIMEOUT),
      };
      this.pendingMessages.push(requestDescriptor);
    });
  }

  public sendPendingMessages(): void {
    while (this.pendingMessages.length > 0) {
      const request = this.pendingMessages[0];
      // Do not send message if we've rejected its promise already
      if (!request.alreadyRejected) {
        try {
          const message = request.message;
          this.actualSend(message)
            .then((response) => request.resolve(response))
            .catch((e) => request.reject(e));
          clearTimeout(request.timeout);
        } catch (e) {
          log.debug("Failed to send pending message", e);
          break;
        }
      }
      this.pendingMessages.splice(0, 1);
    }
  }

  public rejectPendingMessages(): void {
    this.pendingMessages.forEach((message) => {
      message.reject(
        new TransportUnavailableError( // @todo Error Unhandled promise rejection!
          "Unable to connect: " + this.twilsock.getTerminationReason
        )
      );
      message.alreadyRejected = true;
      clearTimeout(message.timeout);
    });

    this.pendingMessages.splice(0, this.pendingMessages.length);
  }

  public async actualSend(message: MessageType): Promise<Result<Context>> {
    const address = message.to as Address;
    const headers = message.headers as ProtocolHeaders;
    const body = message.body;
    const grant = message.grant ?? this.config.activeGrant;

    const httpRequest = {
      host: address.host,
      path: address.path,
      method: address.method,
      params: address.params,
      headers: headers,
    } as ProtocolRequest;

    const upstreamMessage = new Messages.Message(
      grant,
      headers["Content-Type"] || "application/json",
      httpRequest
    );

    log.trace("Sending upstream message", upstreamMessage);

    const reply = await this.transport.sendWithReply(upstreamMessage, body);

    log.trace("Received upstream message response", reply);

    if (isHttpReply(reply) && !isHttpSuccess(reply.header.http_status.code)) {
      throw new TwilsockUpstreamError(
        reply.header.http_status.code,
        reply.header.http_status.status,
        reply.body
      );
    }

    return {
      status: reply.header.http_status,
      headers: reply.header.http_headers,
      body: reply.body,
    };
  }

  /**
   * Send an upstream message
   * @param {string} method The upstream method
   * @param {string} url URL to send the message to
   * @param {object} [headers] The message headers
   * @param {any} [body] The message body
   * @param {string} [grant] The product grant
   * @returns {Promise<Result>} Result from remote side
   */
  public send(
    method: string,
    url: string,
    headers: Headers = {},
    body?: Context | string,
    grant?: string
  ): Promise<Result<Context>> {
    if (this.twilsock.isTerminalState) {
      return Promise.reject(
        new TransportUnavailableError(
          "Unable to connect: " + this.twilsock.getTerminationReason
        )
      );
    }

    const twilsockMessage = twilsockParams(method, url, headers, body, grant);
    if (!this.twilsock.isConnected) {
      return this.saveMessage(twilsockMessage);
    }
    return this.actualSend(twilsockMessage);
  }
}

export { Upstream };
