import { Logger } from "../logger";
import {
  Conversation,
  ConversationDescriptor,
  ConversationUpdatedEventArgs,
  ConversationUpdateReason,
} from "../conversation";
import { SyncMap, SyncClient, SyncMapItem } from "twilio-sync";
import { Users } from "./users";
import { Network } from "../services/network";
import { TypingIndicator } from "../services/typing-indicator";
import { McsClient } from "@twilio/mcs-client";
import { Deferred } from "../util/deferred";
import {
  Participant,
  ParticipantUpdatedEventArgs,
  ParticipantUpdateReason,
} from "../participant";
import {
  Message,
  MessageUpdatedEventArgs,
  MessageUpdateReason,
} from "../message";
import { UriBuilder } from "../util";
import { Configuration } from "../configuration";
import { CommandExecutor } from "../command-executor";
import { CreateConversationRequest } from "../interfaces/commands/create-conversation";
import { ConversationResponse } from "../interfaces/commands/conversation-response";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import isEqual from "lodash.isequal";
import { ResponseMeta } from "../interfaces/commands/response-meta";

type ConversationsEvents = {
  conversationAdded: (conversation: Conversation) => void;
  conversationJoined: (conversation: Conversation) => void;
  conversationLeft: (conversation: Conversation) => void;
  conversationRemoved: (conversation: Conversation) => void;
  conversationUpdated: (data: {
    conversation: Conversation;
    updateReasons: ConversationUpdateReason[];
  }) => void;
  participantJoined: (participant: Participant) => void;
  participantLeft: (participant: Participant) => void;
  participantUpdated: (data: {
    participant: Participant;
    updateReasons: ParticipantUpdateReason[];
  }) => void;
  messageAdded: (message: Message) => void;
  messageRemoved: (message: Message) => void;
  messageUpdated: (data: {
    message: Message;
    updateReasons: MessageUpdateReason[];
  }) => void;
  typingEnded: (participant: Participant) => void;
  typingStarted: (participant: Participant) => void;
};

type ConversationsDataSource = "sync" | "chat" | "rest";

interface ConversationsServices {
  syncClient: SyncClient;
  users: Users;
  typingIndicator: TypingIndicator;
  network: Network;
  mcsClient: McsClient;
  commandExecutor: CommandExecutor;
}

type ConversationRestData = {
  roster: string;
  notificationLevel: "default" | "muted";
  lastConsumedMessageIndex: number;
  channel: string;
  messages: string;
  descriptor: ConversationResponse;
  channel_sid: string;
  status: string;
};

const log = Logger.scope("Conversations");

/**
 * Represents conversations collection
 * {@see Conversation}
 */
class Conversations extends ReplayEventEmitter<ConversationsEvents> {
  public readonly conversations: Map<string, Conversation> = new Map();
  public readonly myConversationsRead: Deferred<boolean> = new Deferred();
  private readonly configuration: Configuration;
  private readonly services: ConversationsServices;
  private readonly tombstones: Set<string> = new Set();
  private myConversationsFetched = false;

  public constructor(
    configuration: Configuration,
    services: ConversationsServices
  ) {
    super();

    this.configuration = configuration;
    this.services = services;
  }

  public async addConversation(options): Promise<Conversation> {
    const attributes =
      typeof options?.attributes !== "undefined" ? options.attributes : {};

    const response = await this.services.commandExecutor.mutateResource<
      CreateConversationRequest,
      ConversationResponse
    >("post", this.configuration.links.conversations, {
      friendly_name: options.friendlyName,
      unique_name: options.uniqueName,
      attributes:
        typeof attributes !== "undefined"
          ? JSON.stringify(attributes)
          : undefined,
    });

    const conversationSid = response.sid ?? null;
    const conversationDocument = response.sync_objects?.conversation ?? null;
    const links = {
      self: response.url,
      ...response.links,
    };
    const existingConversation = this.conversations.get(conversationSid);

    if (existingConversation) {
      await existingConversation._subscribe();
      return existingConversation;
    }

    const conversation = new Conversation(
      {
        channel: conversationDocument,
        entityName: "",
        uniqueName: "",
        attributes: null,
        createdBy: "",
        friendlyName: "",
        lastConsumedMessageIndex: 0,
        dateCreated: null,
        dateUpdated: null,
      },
      conversationSid,
      links,
      this.configuration,
      this.services
    );

    this.conversations.set(conversation.sid, conversation);
    this._registerForEvents(conversation);

    await conversation._subscribe();
    this.emit("conversationAdded", conversation);

    return conversation;
  }

  /**
   * Fetch conversations list and instantiate all necessary objects
   */
  public async fetchConversations(): Promise<Conversations> {
    try {
      const map = await this._getMap();

      map.on("itemAdded", (args) => {
        log.debug(`itemAdded: ${args.item.key}`);

        this._upsertConversation("sync", args.item.key, args.item.data);
      });

      map.on("itemRemoved", (args) => {
        log.debug(`itemRemoved: ${args.key}`);

        const sid = args.key;

        if (!this.myConversationsFetched) {
          this.tombstones.add(sid);
        }

        const conversation = this.conversations.get(sid);

        if (!conversation) {
          return;
        }

        if (conversation.status === "joined") {
          conversation._setStatus("notParticipating", "sync");
          this.emit("conversationLeft", conversation);
        }

        this.conversations.delete(sid);
        this.emit("conversationRemoved", conversation);
        conversation.emit("removed", conversation);
      });

      map.on("itemUpdated", (args) => {
        log.debug(`itemUpdated: ${args.item.key}`);

        this._upsertConversation("sync", args.item.key, args.item.data);
      });

      const myConversations: ConversationRestData[] =
        await this._fetchMyConversations();
      const upserts: Promise<Conversation | null>[] = [];

      for (const conversation of myConversations) {
        upserts.push(
          this._upsertConversation(
            "rest",
            conversation["channel_sid"],
            conversation
          )
        );
      }

      this.myConversationsRead.set(true);

      await Promise.all(upserts);

      this.myConversationsFetched = true;
      this.tombstones.clear();

      log.debug("The conversations list has been successfully fetched");

      return this;
    } catch (error) {
      const errorMessage = "Failed to fetch the conversations list";

      if (this.services.syncClient.connectionState !== "disconnected") {
        log.error(errorMessage, error);
      }

      log.debug(`ERROR: ${errorMessage}`, error);

      throw error;
    }
  }

  public async getConversations() {
    const conversationsMap = await this._getMap();
    const page = await conversationsMap.getItems();

    return this._wrapPaginator(page, (items) =>
      Promise.all(
        items.map((item: SyncMapItem) =>
          this._upsertConversation("sync", item.key, item.data)
        )
      )
    );
  }

  public async getConversation(
    sid: string
  ): Promise<Conversation | undefined | null> {
    const conversationsMap = await this._getMap();
    const page = await conversationsMap.getItems({ key: sid });
    const items = page.items.map((item: SyncMapItem) =>
      this._upsertConversation("sync", item.key, item.data)
    );

    return items.length > 0 ? items[0] : null;
  }

  public async getConversationByUniqueName(
    uniqueName: string
  ): Promise<Conversation | null> {
    const url = new UriBuilder(this.configuration.links.myConversations)
      .path(uniqueName)
      .build();
    const response = await this.services.network.get<ConversationResponse>(url);
    const body = response.body;

    const sid = body.conversation_sid;
    const data = {
      entityName: null,
      lastConsumedMessageIndex: body.last_read_message_index,
      status: body?.status || "unknown",
      friendlyName: body.friendly_name,
      dateUpdated: body.date_updated,
      dateCreated: body.date_created,
      uniqueName: body.unique_name,
      createdBy: body.created_by,
      attributes: body.attributes,
      channel: body.sync_objects.conversation,
      notificationLevel: body?.notification_level,
      sid,
    };

    return sid ? this._upsertConversation("sync", sid, data) : null;
  }

  public async peekConversation(sid: string): Promise<Conversation | null> {
    const url = new UriBuilder(this.configuration.links.conversations)
      .path(sid)
      .build();
    const response = await this.services.network.get<ConversationResponse>(url);
    const body = response.body;

    const data = {
      entityName: null,
      // lastConsumedMessageIndex: body.last_read_message_index,
      status: body?.status || "unknown",
      friendlyName: body.friendly_name,
      dateUpdated: body.date_updated,
      dateCreated: body.date_created,
      uniqueName: body.unique_name,
      createdBy: body.created_by,
      attributes: body.attributes,
      channel: `${sid}.channel`,
      // notificationLevel: body?.notification_level,
      sid,
    };

    return this._upsertConversation("sync", sid, data);
  }

  private async _getMap(): Promise<SyncMap> {
    return await this.services.syncClient.map({
      id: this.configuration.myConversations,
      mode: "open_existing",
    });
  }

  private async _wrapPaginator(page, op) {
    const items = await op(page.items);

    return {
      items,
      hasNextPage: page.hasNextPage,
      hasPrevPage: page.hasPrevPage,
      nextPage: () => page.nextPage().then((x) => this._wrapPaginator(x, op)),
      prevPage: () => page.prevPage().then((x) => this._wrapPaginator(x, op)),
    };
  }

  private async _updateConversation(
    source: ConversationsDataSource,
    conversation: Conversation,
    data
  ): Promise<void> {
    const areSourcesDifferent =
      conversation._statusSource() !== undefined &&
      source !== conversation._statusSource();
    const isChannelSourceSync =
      source !== "rest" || conversation._statusSource() === "sync";

    if (areSourcesDifferent && isChannelSourceSync && source !== "sync") {
      log.trace(
        "upsertConversation: conversation is known from sync and came from chat, ignoring",
        {
          sid: conversation.sid,
          data: data.status,
          conversation: conversation.status,
        }
      );

      return;
    }

    if (data.status === "joined" && conversation.status !== "joined") {
      conversation._setStatus("joined", source);

      const updateData: Partial<ConversationDescriptor> = {};

      if (typeof data.notificationLevel !== "undefined") {
        updateData.notificationLevel = data.notificationLevel;
      }

      if (typeof data.lastConsumedMessageIndex !== "undefined") {
        updateData.lastConsumedMessageIndex = data.lastConsumedMessageIndex;
      }

      if (!isEqual(updateData, {})) {
        conversation._update(updateData);
      }

      conversation._subscribe().then(() => {
        this.emit("conversationJoined", conversation);
      });

      return;
    }

    if (
      data.status === "notParticipating" &&
      conversation.status === "joined"
    ) {
      conversation._setStatus("notParticipating", source);
      conversation._update(data);
      await conversation._subscribe();
      this.emit("conversationLeft", conversation);

      return;
    }

    if (data.status === "notParticipating") {
      await conversation._subscribe();

      return;
    }

    conversation._update(data);
  }

  private async _upsertConversation(
    source: ConversationsDataSource,
    sid: string,
    data
  ): Promise<Conversation | null> {
    log.trace(`upsertConversation called for ${sid}`, data);

    const conversation = this.conversations.get(sid);

    // If the channel is known, update it
    if (conversation) {
      log.trace(
        `upsertConversation: the conversation ${conversation.sid} is known;` +
          `its status is known from the source ${conversation._statusSource()} ` +
          `and the update came from the source ${source}`,
        conversation
      );

      await this._updateConversation(source, conversation, data);
      await conversation._subscribe();

      return conversation;
    }

    // If the conversations is deleted, ignore it
    if (["chat", "rest"].includes(source) && this.tombstones.has(sid)) {
      log.trace(
        "upsertChannel: the channel is deleted but reappeared again from chat, ignoring",
        sid
      );
      return null;
    }

    // If the conversation is unknown, fetch it
    log.trace(
      "upsertConversation: creating a local conversation object with sid " +
        sid,
      data
    );

    const baseLink = `${this.configuration.links.conversations}/${sid}`;
    const links = {
      self: baseLink,
      messages: `${baseLink}/Messages`,
      participants: `${baseLink}/Participants`,
    };
    const newConversation = new Conversation(
      data,
      sid,
      links,
      this.configuration,
      this.services
    );
    this.conversations.set(sid, newConversation);

    await newConversation._subscribe();
    this._registerForEvents(newConversation);
    this.emit("conversationAdded", newConversation);

    if (data.status === "joined") {
      newConversation._setStatus("joined", source);
      this.emit("conversationJoined", newConversation);
    }

    return newConversation;
  }
  private async _fetchMyConversations(): Promise<ConversationRestData[]> {
    let conversations: ConversationRestData[] = [];
    let pageToken: null | string = null;

    do {
      const url = new UriBuilder(this.configuration.links.myConversations);

      if (pageToken) {
        url.arg("PageToken", pageToken);
      }

      const response = await this.services.network.get<
        { conversations: ConversationResponse[] } & ResponseMeta
      >(url.build());
      const receivedConversations = response.body?.conversations.map(
        (conversationDescriptor) => ({
          descriptor: conversationDescriptor,
          channel_sid: conversationDescriptor.conversation_sid,
          status: conversationDescriptor.status,
          channel: conversationDescriptor.sync_objects.conversation,
          messages: conversationDescriptor.sync_objects.messages,
          roster: `${conversationDescriptor.conversation_sid}.roster`,
          lastConsumedMessageIndex:
            conversationDescriptor.last_read_message_index,
          notificationLevel: conversationDescriptor.notification_level,
        })
      );

      pageToken = response.body.meta.next_token;
      conversations = [...conversations, ...receivedConversations];
    } while (pageToken);

    return conversations;
  }

  private _onConversationRemoved(sid: string) {
    const conversation = this.conversations.get(sid);

    if (conversation) {
      this.conversations.delete(sid);
      this.emit("conversationRemoved", conversation);
    }
  }

  private _registerForEvents(conversation) {
    conversation.on("removed", () =>
      this._onConversationRemoved(conversation.sid)
    );
    conversation.on("updated", (args: ConversationUpdatedEventArgs) =>
      this.emit("conversationUpdated", args)
    );
    conversation.on("participantJoined", (participant) =>
      this.emit("participantJoined", participant)
    );
    conversation.on("participantLeft", (participant) =>
      this.emit("participantLeft", participant)
    );
    conversation.on("participantUpdated", (args: ParticipantUpdatedEventArgs) =>
      this.emit("participantUpdated", args)
    );
    conversation.on("messageAdded", (message) =>
      this.emit("messageAdded", message)
    );
    conversation.on("messageUpdated", (args: MessageUpdatedEventArgs) =>
      this.emit("messageUpdated", args)
    );
    conversation.on("messageRemoved", (message) =>
      this.emit("messageRemoved", message)
    );
    conversation.on("typingStarted", (participant) =>
      this.emit("typingStarted", participant)
    );
    conversation.on("typingEnded", (participant) =>
      this.emit("typingEnded", participant)
    );
  }
}

export {
  ConversationsServices,
  ConversationsDataSource,
  Conversation,
  Conversations,
};
