import { SyncError } from './utils/syncerror';
import { deepClone } from './utils/sanitize';
import log from './utils/logger';

import { SyncEntity, EntityServices, RemovalHandler } from './entity';
import { Mutator } from './interfaces/mutator';
import { MergingQueue } from './mergingqueue';
import Closeable from './closeable';
import { nonNegativeInteger, objectSchema, pureObject, validateTypesAsync } from '@twilio/declarative-type-validator';

interface DocumentServices extends EntityServices {
}

interface DocumentDescriptor {
  url: string;
  sid: string;
  revision: string;
  last_event_id: number;
  unique_name: string;
  data: Object;
  date_updated: Date;
  date_expires: string;
}

interface DocumentUpdateRequest {
  data?: Object;
  ttl?: number;
  revision?: string;
}

interface DocumentUpdateResult {
  revision: string;
  data: Object;
  last_event_id: number;
  date_updated: Date;
  date_expires: string | null;
}

/**
 * Document metadata.
 */
interface SyncDocumentMetadata {
  /**
   * Specifies the time-to-live in seconds after which the document is subject to automatic deletion.
   * The value 0 means infinity.
   */
  ttl?: number;
}

class SyncDocumentImpl extends SyncEntity {

  private readonly updateMergingQueue: MergingQueue<SyncDocumentMetadata, Object>;
  private readonly descriptor: DocumentDescriptor;
  private isDeleted: boolean = false;

  /**
   * @internal
   */
  constructor(services: DocumentServices, descriptor: DocumentDescriptor, removalHandler: RemovalHandler) {
    super(services, removalHandler);

    const updateRequestReducer = (acc, input) => (typeof input.ttl === 'number') ? {ttl: input.ttl}
      : acc;
    this.updateMergingQueue = new MergingQueue<SyncDocumentMetadata, Object>(updateRequestReducer);
    this.descriptor = descriptor;
    this.descriptor.data = this.descriptor.data || {};
    this.descriptor.date_updated = new Date(this.descriptor.date_updated);
  }

  // private props
  get uri(): string {
    return this.descriptor.url;
  }

  get revision(): string {
    return this.descriptor.revision;
  }

  get lastEventId(): number {
    return this.descriptor.last_event_id;
  }

  get dateExpires(): string {
    return this.descriptor.date_expires;
  }

  static get type() {
    return 'document';
  }

  get type() {
    return 'document';
  }

  // below properties are specific to Insights only
  get indexName(): string {
    return undefined;
  }

  get queryString(): string {
    return undefined;
  }

  // public props, documented along with class description
  get sid(): string {
    return this.descriptor.sid;
  }

  get data(): Object {
    return this.descriptor.data;
  }

  get dateUpdated(): Date {
    return this.descriptor.date_updated;
  }

  get uniqueName(): string {
    return this.descriptor.unique_name || null;
  }

  /**
   * Update data entity with new data
   * @private
   */
  _update(update): void {
    update.date_created = new Date(update.date_created);
    switch (update.type) {
      case 'document_updated':
        if (update.id <= this.lastEventId) {
          log.trace('Document update skipped, current:', this.lastEventId, ', remote:', update.id);
          break;
        }

        const previousData = this.descriptor.data !== undefined ? deepClone(this.descriptor.data) : null;

        this.descriptor.last_event_id = update.id;
        this.descriptor.revision = update.document_revision;
        this.descriptor.date_updated = update.date_created;
        this.descriptor.data = update.document_data;

        this.broadcastEventToListeners('updated', {data: update.document_data, isLocal: false, previousData});
        this.services.storage.update(this.type, this.sid, this.uniqueName,
          {
            last_event_id: update.id,
            revision: update.document_revision,
            date_updated: update.date_created,
            data: update.document_data
          });
        break;
      case 'document_removed':
        this.onRemoved(false);
        break;
    }
  }

  public async set(value: Object, metadataUpdates?: SyncDocumentMetadata): Promise<Object> {
    const input: SyncDocumentMetadata = metadataUpdates || {};
    return this.updateMergingQueue.squashAndAdd(input, input => this._setUnconditionally(value, input.ttl));
  }

  public async mutate(mutator: Mutator, metadataUpdates?: SyncDocumentMetadata): Promise<Object> {
    const input: SyncDocumentMetadata = metadataUpdates || {};
    return this.updateMergingQueue.add(input, input => this._setWithIfMatch(mutator, input.ttl));
  }

  public async update(obj: Object, metadataUpdates?: SyncDocumentMetadata): Promise<Object> {
    return this.mutate(remote => Object.assign(remote, obj), metadataUpdates);
  }

  public async setTtl(ttl: number): Promise<void> {
    const response = await this._postUpdateToServer({ttl});
    this.descriptor.date_expires = response.date_expires;
  }

  /**
   * @private
   */
  private async _setUnconditionally(value: Object, ttl?: number): Promise<Object> {
    let result = await this._postUpdateToServer({data: value, revision: undefined, ttl});
    this._handleSuccessfulUpdateResult(result);
    return this.descriptor.data;
  }

  /**
   * @private
   */
  private async _setWithIfMatch(mutatorFunction: Mutator, ttl?: number): Promise<Object> {
    let data = mutatorFunction(deepClone(this.descriptor.data));
    if (data) {
      let revision = this.revision;
      try {
        let result = await this._postUpdateToServer({data, revision, ttl});
        this._handleSuccessfulUpdateResult(result);
        return this.descriptor.data;
      } catch (error) {
        if (error.status === 412) {
          await this._softSync();
          return this._setWithIfMatch(mutatorFunction);
        } else {
          throw error;
        }
      }
    } else {
      return this.descriptor.data;
    }
  }

  /**
   * @private
   */
  private _handleSuccessfulUpdateResult(result: DocumentUpdateResult) {
    // Ignore returned value if we already got a newer one
    if (result.last_event_id <= this.descriptor.last_event_id) {
      return;
    }

    const previousData = this.descriptor.data !== undefined ? deepClone(this.descriptor.data) : null;

    this.descriptor.revision = result.revision;
    this.descriptor.data = result.data;
    this.descriptor.last_event_id = result.last_event_id;
    this.descriptor.date_expires = result.date_expires;
    this.descriptor.date_updated = new Date(result.date_updated);

    this.services.storage.update(this.type, this.sid, this.uniqueName,
      {
        last_event_id: result.last_event_id,
        revision: result.revision,
        date_updated: result.date_updated,
        data: result.data
      });
    this.broadcastEventToListeners('updated', {data: this.descriptor.data, isLocal: true, previousData});
  }

  /**
   * @private
   */
  private async _postUpdateToServer(request: DocumentUpdateRequest): Promise<DocumentUpdateResult> {
    if (!this.isDeleted) {
      const requestBody: any = {
        data: request.data
      };

      if (request.ttl !== undefined) {
        requestBody.ttl = request.ttl;
      }

      const ifMatch = request.revision;
      try {
        const response = await this.services.network.post(this.uri, requestBody, ifMatch);
        return {
          revision: response.body.revision,
          data: request.data,
          last_event_id: response.body.last_event_id,
          date_updated: response.body.date_updated,
          date_expires: response.body.date_expires
        };
      } catch (error) {
        if (error.status === 404) {
          this.onRemoved(false);
        }
        throw error;
      }
    } else {
      return Promise.reject(new SyncError('The Document has been removed', 404, 54100));
    }
  }

  /**
   * Get new data from server
   * @private
   */
  private async _softSync() {
    return this.services.network.get(this.uri)
      .then(response => {
        const event = {
          type: 'document_updated',
          id: response.body.last_event_id,
          document_revision: response.body.revision,
          document_data: response.body.data,
          date_created: response.body.date_updated
        };
        this._update(event);
        return this;
      })
      .catch(err => {
        if (err.status === 404) {
          this.onRemoved(false);
        } else {
          log.error(`Can't get updates for ${this.sid}:`, err);
        }
      });
  }

  protected onRemoved(locally: boolean) {
    if (this.isDeleted) {
      return;
    }

    const previousData = this.descriptor.data !== undefined ? deepClone(this.descriptor.data) : null;

    this.isDeleted = true;
    this._unsubscribe();
    this.removalHandler(this.type, this.sid, this.uniqueName);
    this.broadcastEventToListeners('removed', {isLocal: locally, previousData});
  }

  public async removeDocument() {
    if (!this.isDeleted) {
      await this.services.network.delete(this.uri);
      this.onRemoved(true);
    } else {
      return Promise.reject(new SyncError('The Document has been removed', 404, 54100));
    }
  }

}

/**
 * Represents a Sync document, the contents of which is a single JSON object.
 * Use the {@link SyncClient.document} method to obtain a reference to a Sync document.
 * Information about rate limits can be found [here](https://www.twilio.com/docs/sync/limits).
 */
class SyncDocument extends Closeable {

  private readonly syncDocumentImpl: SyncDocumentImpl;

  // private props
  get uri(): string {
    return this.syncDocumentImpl.uri;
  }

  get revision(): string {
    return this.syncDocumentImpl.revision;
  }

  get lastEventId(): number {
    return this.syncDocumentImpl.lastEventId;
  }

  get dateExpires(): string {
    return this.syncDocumentImpl.dateExpires;
  }

  static get type() {
    return SyncDocumentImpl.type;
  }

  get type() {
    return SyncDocumentImpl.type;
  }

  /**
   * The immutable identifier of this document, assigned by the system.
   */
  get sid(): string {
    return this.syncDocumentImpl.sid;
  }

  /**
   * The contents of this document.
   */
  get data(): Object {
    return this.syncDocumentImpl.data;
  }

  /**
   * Date when the document was last updated.
   */
  get dateUpdated(): Date {
    return this.syncDocumentImpl.dateUpdated;
  }

  /**
   * An optional immutable identifier that may be assigned by the programmer
   * to this document during creation. Globally unique among other documents.
   */
  get uniqueName(): string {
    return this.syncDocumentImpl.uniqueName;
  }

  /**
   * @internal
   */
  constructor(syncDocumentImpl: SyncDocumentImpl) {
    super();
    this.syncDocumentImpl = syncDocumentImpl;
    this.syncDocumentImpl.attach(this);
  }

  /**
   * Fired when the document is removed, regardless of whether the remover was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has following properties:
   *     * boolean `isLocal` - is true if document was removed by a local actor, false otherwise
   *     * object `previousData` - contains a snapshot of the document data before removal
   * @example
   * ```typescript
   * document.on('removed', (args) => {
   *   console.log(`Document ${document.sid} was removed`);
   *   console.log('args.isLocal:', args.isLocal);
   *   console.log('args.previousData:', args.previousData);
   * });
   * ```
   * @event
   */
  static readonly removed = 'removed';

  /**
   * Fired when the document's contents have changed, regardless of whether the updater was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * boolean `isLocal` - is true if document was updated by a local actor, false otherwise
   *     * object `data` - a snapshot of the document's new contents
   *     * object `previousData` - contains a snapshot of the document data before the update
   * @example
   * ```typescript
   * document.on('updated', (args) => {
   *   console.log(`Document ${document.sid} was updated`);
   *   console.log('args.data:', args.data);
   *   console.log('args.isLocal:', args.isLocal);
   *   console.log('args.previousData:', args.previousData);
   * });
   * ```
   * @event
   */
  static readonly updated = 'updated';

  /**
   * Assign new contents to this document. The current data will be overwritten.
   * @param data The new contents to assign.
   * @param metadataUpdates New document metadata.
   * @return A promise resolving to the new data of the document.
   * @example
   * ```typescript
   * // Say, the Document data is `{ name: 'John Smith', age: 34 }`
   * document.set({ name: 'Barbara Oaks' }, { ttl: 86400 })
   *   .then((newValue) => {
   *     // Now the Document data is `{ name: 'Barbara Oaks' }`
   *     console.log('Document set() successful, new data:', newValue);
   *   })
   *   .catch((error) => {
   *     console.error('Document set() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    pureObject,
    [
      'undefined',
      objectSchema('document metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )
  public async set(data: Object, metadataUpdates?: SyncDocumentMetadata): Promise<Object> {
    this.ensureNotClosed();
    return this.syncDocumentImpl.set(data, metadataUpdates);
  }

  /**
   * Schedules a modification to this document that will apply a mutation function.
   * @param mutator A function that outputs new data based on the existing data.
   * May be called multiple times, particularly if this document is modified concurrently by remote code.
   * If the mutation ultimately succeeds, the document will have made the particular transition described
   * by this function.
   * @param metadataUpdates New document metadata.
   * @return Resolves with the most recent Document state, whether the output of a
   * successful mutation or a state that prompted graceful cancellation (mutator returned `null`).
   * @example
   * ```typescript
   * const mutatorFunction = (currentValue) => {
   *     currentValue.viewCount = (currentValue.viewCount ?? 0) + 1;
   *     return currentValue;
   * };
   * document.mutate(mutatorFunction, { ttl: 86400 }))
   *   .then((newValue) => {
   *     console.log('Document mutate() successful, new data:', newValue);
   *   })
   *   .catch((error) => {
   *     console.error('Document mutate() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    'function',
    [
      'undefined',
      objectSchema('document metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )
  public async mutate(mutator: Mutator, metadataUpdates?: SyncDocumentMetadata): Promise<Object> {
    this.ensureNotClosed();
    return this.syncDocumentImpl.mutate(mutator, metadataUpdates);
  }

  /**
   * Modify a document by appending new fields (or by overwriting existing ones) with the values from the provided Object.
   * This is equivalent to:
   * ```typescript
   * document.mutate((currentValue) => Object.assign(currentValue, obj));
   * ```
   * @param obj Specifies the particular (top-level) attributes that will receive new values.
   * @param metadataUpdates New document metadata.
   * @return A promise resolving to the new data of the document.
   * @example
   * ```typescript
   * // Say, the Document data is `{ name: 'John Smith' }`
   * document.update({ age: 34 }, { ttl: 86400 })
   *   .then((newValue) => {
   *     // Now the Document data is `{ name: 'John Smith', age: 34 }`
   *     console.log('Document update() successful, new data:', newValue);
   *   })
   *   .catch((error) => {
   *     console.error('Document update() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    pureObject,
    [
      'undefined',
      objectSchema('document metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )
  public async update(obj: Object, metadataUpdates?: SyncDocumentMetadata): Promise<Object> {
    this.ensureNotClosed();
    return this.syncDocumentImpl.update(obj, metadataUpdates);
  }

  /**
   * Update the time-to-live of the document.
   * @param ttl Specifies the time-to-live in seconds after which the document is subject to automatic deletion. The value 0 means infinity.
   * @return A promise that resolves after the TTL update was successful.
   * @example
   * ```typescript
   * document.setTtl(3600)
   *   .then(() => {
   *     console.log('Document setTtl() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Document setTtl() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(nonNegativeInteger)
  public async setTtl(ttl: number): Promise<void> {
    this.ensureNotClosed();
    return this.syncDocumentImpl.setTtl(ttl);
  }

  /**
   * Delete a document.
   * @return A promise which resolves if (and only if) the document is ultimately deleted.
   * @example
   * ```typescript
   * document.removeDocument()
   *   .then(() => {
   *     console.log('Document removeDocument() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Document removeDocument() failed', error);
   *   });
   * ```
   */
  public async removeDocument() {
    this.ensureNotClosed();
    return this.syncDocumentImpl.removeDocument();
  }

  /**
   * Conclude work with the document instance and remove all event listeners attached to it.
   * Any subsequent operation on this object will be rejected with error.
   * Other local copies of this document will continue operating and receiving events normally.
   * @example
   * ```typescript
   * document.close();
   * ```
   */
  public close(): void {
    super.close();
    this.syncDocumentImpl.detach(this.listenerUuid);
  }

}

export { SyncDocumentMetadata, DocumentServices, DocumentDescriptor, Mutator, SyncDocument, SyncDocumentImpl };
export default SyncDocument;
