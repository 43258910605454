import { EventEmitter } from "events";

/**
 * Provides retrier service
 */
class Retrier extends EventEmitter {
  private readonly minDelay: number;
  private maxDelay: number;
  private readonly initialDelay: number;
  private readonly maxAttemptsCount: number;
  private readonly maxAttemptsTime: number;
  private readonly randomness: number;

  // fibonacci strategy
  private prevDelay: number;
  private currDelay: number;

  private timeout: ReturnType<typeof setTimeout> | null = null;
  private inProgress: boolean;
  private attemptNum: number;
  private startTimestamp = -1;

  /**
   * Creates a new Retrier instance
   */
  constructor(options: {
    min: number;
    max: number;
    initial?: number;
    maxAttemptsCount?: number;
    maxAttemptsTime?: number;
    randomness?: number;
  }) {
    super();

    this.minDelay = options.min;
    this.maxDelay = options.max;
    this.initialDelay = options.initial || 0;
    this.maxAttemptsCount = options.maxAttemptsCount || 0;
    this.maxAttemptsTime = options.maxAttemptsTime || 0;
    this.randomness = options.randomness || 0;

    this.inProgress = false;
    this.attemptNum = 0;

    this.prevDelay = 0;
    this.currDelay = 0;
  }

  private attempt() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.attemptNum++;
    this.emit("attempt", this);
  }

  private nextDelay(delayOverride?: number): number {
    if (typeof delayOverride === "number") {
      this.prevDelay = 0;
      this.currDelay = delayOverride;
      return delayOverride;
    }

    if (this.attemptNum == 0) {
      return this.initialDelay;
    }

    if (this.attemptNum == 1) {
      this.currDelay = this.minDelay;
      return this.currDelay;
    }

    this.prevDelay = this.currDelay;

    let delay = this.currDelay + this.prevDelay;

    if (this.maxDelay && delay > this.maxDelay) {
      this.currDelay = this.maxDelay;
      delay = this.maxDelay;
    }

    this.currDelay = delay;
    return delay;
  }

  private randomize(delay: number) {
    const area = delay * this.randomness;
    const corr = Math.round(Math.random() * area * 2 - area);
    return Math.max(0, delay + corr);
  }

  private scheduleAttempt(delayOverride?: number) {
    if (this.maxAttemptsCount && this.attemptNum >= this.maxAttemptsCount) {
      this.cleanup();
      this.emit("failed", new Error("Maximum attempt count limit reached"));
      return;
    }

    let delay = this.nextDelay(delayOverride);
    delay = this.randomize(delay);
    if (
      this.maxAttemptsTime &&
      this.startTimestamp + this.maxAttemptsTime < Date.now() + delay
    ) {
      this.cleanup();
      this.emit("failed", new Error("Maximum attempt time limit reached"));
      return;
    }

    this.timeout = setTimeout(() => this.attempt(), delay);
  }

  private cleanup() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.inProgress = false;

    this.attemptNum = 0;
    this.prevDelay = 0;
    this.currDelay = 0;
  }

  public start(): void {
    if (this.inProgress) {
      throw new Error("Retrier is already in progress");
    }

    this.inProgress = true;
    this.startTimestamp = Date.now();
    this.scheduleAttempt(this.initialDelay);
  }

  public cancel(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.inProgress = false;

      this.emit("cancelled");
    }
  }

  // @todo Must be a T here, so the entire Retrier must be typed on this value type.
  // eslint-disable-next-line
  public succeeded(arg?: any): void {
    this.emit("succeeded", arg);
  }

  public failed(err: Error, nextAttemptDelayOverride?: number): void {
    if (this.timeout) {
      throw new Error("Retrier attempt is already in progress");
    }

    this.scheduleAttempt(nextAttemptDelayOverride);
  }
}

/**
 * Run retrier as an async function with possibility to await for it.
 * Example:
 * ```
 * const result = AsyncRetrier.run(async () => somePromise);
 * ```
 */
class AsyncRetrier extends EventEmitter {
  private retrier: Retrier;
  // This any must be T typed directly on the AsyncRetrier
  // eslint-disable-next-line
  private resolve: (value: any) => void = () => void 0;
  private reject: (err?: Error) => void = () => void 0;

  constructor(options: {
    min: number;
    max: number;
    initial?: number;
    maxAttemptsCount?: number;
    maxAttemptsTime?: number;
    randomness?: number;
  }) {
    super();
    this.retrier = new Retrier(options);
  }

  public run<T>(handler: () => Promise<T>): Promise<T> {
    this.retrier.on("attempt", () => {
      handler()
        .then((v) => this.retrier.succeeded(v))
        .catch((e) => this.retrier.failed(e));
    });

    this.retrier.on("succeeded", (arg) => this.resolve(arg));
    this.retrier.on("cancelled", () => this.reject(new Error("Cancelled")));
    this.retrier.on("failed", (err) => this.reject(err));

    return new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      this.retrier.start();
    });
  }

  public cancel(): void {
    this.retrier.cancel();
  }
}

export { Retrier, AsyncRetrier };
