import { RuleDefinition, TypeRule } from "../type-validator";
import { custom } from "./custom";

export const type = (...checks: TypeRule[]): RuleDefinition =>
  custom((value: unknown) => {
    let isValid = false;
    const expectedTypes: string[] = [];

    for (const type of checks) {
      if (typeof type === "string") {
        isValid = isValid || typeof value === type;
        expectedTypes.push(`of type ${type}`);

        continue;
      }

      isValid = isValid || value instanceof type;
      expectedTypes.push(`an instance of ${type.name}`);
    }

    return [isValid, expectedTypes];
  });
