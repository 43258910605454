export interface SyncMapItemDescriptor {
  key: string;
  url: string;
  revision: string;
  last_event_id: number;
  date_updated: Date;
  date_expires: string;
  data: Object;
}

/**
 * Represents an individual element in a Sync map.
 */
class SyncMapItem {
  private readonly descriptor: SyncMapItemDescriptor;

  /**
   * @internal
   */
  constructor(descriptor: SyncMapItemDescriptor) {
    this.descriptor = descriptor;
  }

  get uri(): string {
    return this.descriptor.url;
  }

  get revision(): string {
    return this.descriptor.revision;
  }

  get lastEventId(): number {
    return this.descriptor.last_event_id;
  }

  get dateExpires(): string {
    return this.descriptor.date_expires;
  }

  /**
   * The identifier that maps to this item within the containing Map.
   */
  get key(): string {
    return this.descriptor.key;
  }

  /**
   * The contents of the item.
   */
  get data(): Object {
    return this.descriptor.data;
  }

  /**
   * Date when the map item was last updated.
   */
  get dateUpdated(): Date {
    return this.descriptor.date_updated;
  }

  /**
   * @internal
   */
  update(eventId: number, revision: string, value: Object, dateUpdated: Date): SyncMapItem {
    this.descriptor.last_event_id = eventId;
    this.descriptor.revision = revision;
    this.descriptor.data = value;
    this.descriptor.date_updated = dateUpdated;
    return this;
  }

  /**
   * @internal
   */
  updateDateExpires(dateExpires: string) {
    this.descriptor.date_expires = dateExpires;
  }
}

export { SyncMapItem };
