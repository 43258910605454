/**
 * Push notification type.
 */
type PushNotificationType =
  | "twilio.conversations.new_message"
  | "twilio.conversations.added_to_conversation"
  | "twilio.conversations.removed_from_conversation";

interface PushNotificationDescriptor {
  title: string | null;
  body: string | null;
  sound: string | null;
  badge: number | null;
  action: string | null;
  type: PushNotificationType;
  data: Record<string, unknown>;
}

/**
 * Additional data for a given push notification.
 */
interface PushNotificationData {
  /**
   * SID of the conversation.
   */
  conversationSid?: string;

  /**
   * Index of the message in the conversation.
   */
  messageIndex?: number;

  /**
   * SID of the message in the conversation.
   */
  messageSid?: string;
}

/**
 * Push notification for a Conversations client.
 */
class PushNotification {
  /**
   * Title of the notification.
   */
  public readonly title: string | null;

  /**
   * Text of the notification.
   */
  public readonly body: string | null;

  /**
   * Sound of the notification.
   */
  public readonly sound: string | null;

  /**
   * Number of the badge.
   */
  public readonly badge: number | null;

  /**
   * Notification action (`click_action` in FCM terms and `category` in APN terms).
   */
  public readonly action: string | null;

  /**
   * Type of the notification.
   */
  public readonly type: PushNotificationType;

  /**
   * Additional data of the conversation.
   */
  public readonly data: PushNotificationData;

  /**
   * @internal
   */
  constructor(data: PushNotificationDescriptor) {
    this.title = data.title || null;
    this.body = data.body || null;
    this.sound = data.sound || null;
    this.badge = data.badge || null;
    this.action = data.action || null;
    this.type = data.type || null;
    this.data = data.data || {};
  }
}

export {
  PushNotification,
  PushNotificationType,
  PushNotificationDescriptor,
  PushNotificationData,
};
