import * as uuid from "uuid";
import { RegistrationState, UpdateReason, Connector } from "./connector";
import { TwilsockClient } from "twilsock";
import { log } from "./logger";

/**
 * Registrar connector implementation for twilsock -- @todo Drop twilsock.connector COMPLETELY?!
 */
class TwilsockConnector extends Connector {
  private readonly contextId: string = uuid.v4();
  /**
   * Create twilsock registration connector.
   * @param productId product ID
   * @param platform platform ID string
   * @param twilsock {TwilsockClient} connection transport.
   */
  constructor(
    private readonly productId: string,
    private readonly platform: string,
    private readonly twilsock: TwilsockClient
  ) {
    super("twilsock");
  }

  protected async updateRegistration(
    registration: RegistrationState,
    reasons: Set<UpdateReason>
  ): Promise<RegistrationState> {
    if (!reasons.has("messageType")) {
      // No changed message types - it is fine, finish successfully.
      return registration;
    }

    const messageTypes = Array.from(registration.messageTypes);

    const context = {
      product_id: this.productId,
      notification_protocol_version: 4,
      endpoint_platform: this.platform,
      message_types: messageTypes,
    };

    try {
      await this.twilsock.setNotificationsContext(this.contextId, context);
    } catch (err) {
      log.error(`Failed to update twilsock notification context: ${err}`);
      throw err;
    }

    return registration;
  }

  protected async removeRegistration(): Promise<void> {
    try {
      await this.twilsock.removeNotificationsContext(this.contextId);
    } catch (err) {
      log.error(`Failed to remove twilsock notification context: ${err}`);
      throw err;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async sendDeviceRemoveRequest(registrationId: string): Promise<void> {
    // no need to do anything here, twilsock backend handles it on its own
  }
}

export { TwilsockConnector };
