class NotificationTypes {
  static readonly TYPING_INDICATOR = "twilio.ipmsg.typing_indicator";
  static readonly NEW_MESSAGE = "twilio.conversations.new_message";
  static readonly ADDED_TO_CONVERSATION =
    "twilio.conversations.added_to_conversation";
  // static readonly INVITED_TO_CHANNEL = 'twilio.channel.invited_to_channel';
  static readonly REMOVED_FROM_CONVERSATION =
    "twilio.conversations.removed_from_conversation";
  static readonly CONSUMPTION_UPDATE = "twilio.channel.consumption_update";
}

export { NotificationTypes };
