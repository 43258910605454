import { Logger } from "../logger";

import { Notifications } from "@twilio/notifications";

import { NotificationTypes } from "../interfaces/notification-types";
import { TwilsockClient } from "twilsock";
import { Configuration } from "../configuration";

const log = Logger.scope("TypingIndicator");

export interface TypingIndicatorServices {
  twilsockClient: TwilsockClient;
  notificationClient: Notifications;
}

/**
 * An important note in regards to typing timeout timers. There are two places that the SDK can get the "typing_timeout" attribute from. The first
 * place that the attribute appears in is the response received from POST -> /v1/typing REST call. In the body of that response, the value of the
 * "typing_timeout" attribute will be exactly the same as defined in the console. The second place that the attribute appears in is from a
 * notification of type "twilio.ipmsg.typing_indicator". In this case, the "typing_timeout" value will be +1 of that in the console. This
 * intentional. The timeout returned from the POST -> /v1/typing call should be used to disable further calls for that period of time. On contrary,
 * the timeout returned from the notification should be used as the timeout for the "typingEnded" event, +1 is to account for latency.
 *
 * @private
 */

/**
 * @class TypingIndicator
 *
 * @constructor
 * @private
 */
class TypingIndicator {
  private readonly services: TypingIndicatorServices;
  private readonly configuration: Configuration;

  private sentUpdates: Map<string, number>;
  private getConversation;
  private serviceTypingTimeout;

  constructor(
    getConversation,
    config: Configuration,
    services: TypingIndicatorServices
  ) {
    this.configuration = config;
    this.services = services;
    this.getConversation = getConversation;

    this.serviceTypingTimeout = null;
    this.sentUpdates = new Map();
  }

  public get typingTimeout(): number {
    return (
      this.configuration.typingIndicatorTimeoutOverride ||
      this.serviceTypingTimeout ||
      this.configuration.typingIndicatorTimeoutDefault
    );
  }

  /**
   * Initialize TypingIndicator controller
   * Registers for needed message types and sets listeners
   * @private
   */
  initialize(): void {
    // this.services.notificationClient.subscribe(NotificationTypes.TYPING_INDICATOR, 'twilsock');
    this.services.notificationClient.on("message", async (type, message) => {
      if (type === NotificationTypes.TYPING_INDICATOR) {
        await this._handleRemoteTyping(message);
      }
    });
  }

  /**
   * Remote participants typing events handler
   */
  private async _handleRemoteTyping(message) {
    log.trace("Got new typing indicator ", message);

    this.getConversation(message.channel_sid)
      .then((conversation) => {
        if (!conversation) {
          return;
        }

        conversation.participants.forEach((participant) => {
          if (participant.identity !== message.identity) {
            return;
          }

          const timeout = this.configuration.typingIndicatorTimeoutOverride
            ? this.configuration.typingIndicatorTimeoutOverride + 1000
            : message.typing_timeout * 1000;
          participant._startTyping(timeout);
        });
      })
      .catch((err) => {
        log.error(err);
        throw err;
      });
  }

  /**
   * Send typing event for the given conversation sid
   * @param {String} conversationSid
   */
  send(conversationSid: string) {
    const lastUpdate = this.sentUpdates.get(conversationSid);
    if (lastUpdate && lastUpdate > Date.now() - this.typingTimeout) {
      return Promise.resolve();
    }

    this.sentUpdates.set(conversationSid, Date.now());
    return this._send(conversationSid);
  }

  private _send(conversationSid: string) {
    log.trace("Sending typing indicator");

    const url = this.configuration.links.typing;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const body = `ChannelSid=${conversationSid}`;

    return this.services.twilsockClient
      .post<{ typing_timeout: number }>(
        url,
        headers,
        body,
        this.configuration.productId
      )
      .then((response) => {
        if (response.body.hasOwnProperty("typing_timeout")) {
          this.serviceTypingTimeout = response.body.typing_timeout * 1000;
        }
      })
      .catch((err) => {
        log.error("Failed to send typing indicator:", err);
        throw err;
      });
  }
}

export { TypingIndicator };
