import { v4 as uuid } from "uuid";

class AbstractMessage {
  public readonly id: string;

  constructor(id?: string) {
    this.id = id || `TM${uuid()}`;
  }
}

export { AbstractMessage };
