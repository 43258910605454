import { TreeMap } from './utils/tree';

interface CacheEntry<T> {
  value: T;
  revision: number;
  isValid: boolean;
}

class Entry<T> implements CacheEntry<T> {
  value: T;
  revision: number;

  constructor(value: T, revision: number) {
    this.value = value;
    this.revision = (revision || 0);
  }

  get isValid(): boolean {
    return true;
  }
}

class Tombstone<T> implements CacheEntry<T> {
  value: T;
  revision: number;

  constructor(revision: number) {
    this.revision = revision;
  }

  get isValid(): boolean {
    return false;
  }
}

class Cache<K, V> {
  public readonly items: TreeMap<K, CacheEntry<V>>;

  constructor() {
    this.items = new TreeMap<K, CacheEntry<V>>();
  }

  store(key: K, value: V, revision: number): V {
    let entry = this.items.get(key);
    if (entry && entry.revision > revision) {
      if (entry.isValid) {
        return entry.value;
      }
      return null;
    }
    this.items.set(key, new Entry<V>(value, revision));
    return value;
  }

  delete(key: K, revision: number, force: boolean = false): void {
    let curr = this.items.get(key);
    if (!curr || curr.revision < revision ||
      (curr && force === true) /* forced delete when revision is unknown */) {
      this.items.set(key, new Tombstone<V>(revision));
    }
  }

  isKnown(key: K, revision: number): boolean {
    let curr = this.items.get(key);
    return curr && curr.revision >= revision;
  }

  get(key: K): V {
    let entry = this.items.get(key);
    if (entry && entry.isValid) {
      return entry.value;
    }
    return null;
  }

  has(key: K): boolean {
    let entry = this.items.get(key);
    return entry && entry.isValid;
  }

  forEach(callbackfn: (key: K, value: V) => void): void {
    if (this.items) {
      for (let [key, entry] of this.items) {
        if (entry.isValid) {
          callbackfn(key, entry.value);
        }
      }
    }
  }
}

export { Cache };
export default Cache;
