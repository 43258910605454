class Deferred<T> {
  private readonly _promise: Promise<T>;
  private _resolve;
  private _reject;

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public get promise(): Promise<T> {
    return this._promise;
  }

  update(value: T): void {
    this._resolve(value);
  }

  set(value: T): void {
    this._resolve(value);
  }

  fail(e: Error): void {
    this._reject(e);
  }
}

export { Deferred };
