import { EntityServices, RemovalHandler, SyncEntity } from '../entity';
import { PublishMessageRequest, PublishMessageResponse, StreamDescriptor } from './serverapi';
import Closeable from '../closeable';
import { nonNegativeInteger, pureObject, validateTypesAsync } from '@twilio/declarative-type-validator';

export interface SyncStreamServices extends EntityServices {
}

/**
 * Stream message descriptor.
 */
export interface SyncStreamMessage {
  /**
   * Stream message SID.
   */
  sid: string;

  /**
   * Stream message data.
   */
  data: object;
}

class SyncStreamImpl extends SyncEntity {

  private readonly descriptor: StreamDescriptor;

  /**
   * @internal
   */
  constructor(services: SyncStreamServices, descriptor: StreamDescriptor, removalHandler: RemovalHandler) {
    super(services, removalHandler);
    this.descriptor = descriptor;
  }

  // private props
  get uri(): string {
    return this.descriptor.url;
  }

  get links(): any {
    return this.descriptor.links;
  }

  static get type() {
    return 'stream';
  }

  get dateExpires(): string {
    return this.descriptor.date_expires;
  }

  get type() {
    return 'stream';
  }

  get lastEventId() {
    return null;
  }

  // below properties are specific to Insights only
  get indexName(): string {
    return undefined;
  }

  get queryString(): string {
    return undefined;
  }

  // public props, documented along with class description
  get sid() {
    return this.descriptor.sid;
  }

  get uniqueName() {
    return this.descriptor.unique_name || null;
  }

  @validateTypesAsync(pureObject)
  public async publishMessage(data: object): Promise<SyncStreamMessage> {
    const requestBody: PublishMessageRequest = {data};
    const response = await this.services.network.post(this.links.messages, requestBody);
    const responseBody: PublishMessageResponse = response.body;

    const event = this._handleMessagePublished(responseBody.sid, data, false);
    return event;
  }

  @validateTypesAsync(nonNegativeInteger)
  public async setTtl(ttl: number): Promise<void> {
    try {
      const requestBody = {ttl: ttl};
      const response = await this.services.network.post(this.uri, requestBody);
      this.descriptor.date_expires = response.body.date_expires;
    } catch (error) {
      if (error.status === 404) {
        this.onRemoved(false);
      }
      throw error;
    }
  }

  public async removeStream() {
    await this.services.network.delete(this.uri);
    this.onRemoved(true);
  }

  /**
   * Handle event from the server
   * @private
   */
  _update(update): void {
    switch (update.type) {
      case 'stream_message_published': {
        this._handleMessagePublished(update.message_sid, update.message_data, true);
        break;
      }
      case 'stream_removed': {
        this.onRemoved(false);
        break;
      }
    }
  }

  private _handleMessagePublished(sid: string, data: object, remote: boolean): SyncStreamMessage {
    const event: SyncStreamMessage = {
      sid: sid,
      data: data
    };

    this.broadcastEventToListeners('messagePublished', {message: event, isLocal: !remote});
    return event;
  }

  protected onRemoved(isLocal: boolean) {
    this._unsubscribe();
    this.removalHandler(this.type, this.sid, this.uniqueName);
    this.broadcastEventToListeners('removed', {isLocal: isLocal});
  }
}

/**
 * A Sync primitive for pub-sub messaging. Stream Messages are not persisted, exist
 * only in transit, and will be dropped if (due to congestion or network anomalies) they
 * cannot be delivered promptly. Use the {@link SyncClient.stream} method to obtain a reference to a Sync Message Stream.
 * Information about rate limits can be found [here](https://www.twilio.com/docs/sync/limits).
 */
class SyncStream extends Closeable {

  private readonly syncStreamImpl: SyncStreamImpl;

  // private props
  get uri(): string {
    return this.syncStreamImpl.uri;
  }

  get links(): any {
    return this.syncStreamImpl.links;
  }

  static get type() {
    return SyncStreamImpl.type;
  }

  get dateExpires(): string {
    return this.syncStreamImpl.dateExpires;
  }

  get type() {
    return SyncStreamImpl.type;
  }

  get lastEventId() {
    return null;
  }

  /**
   * The immutable system-assigned identifier of this stream. Never null.
   */
  get sid() {
    return this.syncStreamImpl.sid;
  }

  /**
   * A unique identifier optionally assigned to the stream on creation.
   */
  get uniqueName() {
    return this.syncStreamImpl.uniqueName;
  }

  /**
   * @internal
   */
  constructor(syncStreamImpl: SyncStreamImpl) {
    super();
    this.syncStreamImpl = syncStreamImpl;
    this.syncStreamImpl.attach(this);
  }

  /**
   * Fired when a message is published to the stream either locally or by a remote actor.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * {@link SyncStreamMessage} `message` -  Published message
   *     * boolean `isLocal` - equals true if the message was published by a local actor, false otherwise
   * @example
   * ```typescript
   * stream.on('messagePublished', (args) => {
   *   console.log('Stream message published');
   *   console.log('Message SID:', args.message.sid);
   *   console.log('Message data: ', args.message.data);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly messagePublished = 'messagePublished';

  /**
   * Fired when a stream is removed entirely, regardless of whether the remover was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * boolean `isLocal` - equals true if the stream was removed by a local actor, false otherwise
   * @example
   * ```typescript
   * stream.on('removed', (args) => {
   *   console.log(`Stream ${stream.sid} was removed`);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly removed = 'removed';

  /**
   * Publish a message to the stream. The system will attempt delivery to all online subscribers.
   * @param data The body of the dispatched message. Maximum size in serialized JSON: 4KB.
   * A rate limit applies to this operation, refer to the [Sync API documentation](https://www.twilio.com/docs/api/sync) for details.
   * @return A promise which resolves after the message is successfully published
   * to the Sync service. Resolves irrespective of ultimate delivery to any subscribers.
   * @example
   * ```typescript
   * stream.publishMessage({ x: 42, y: 123 })
   *   .then((message) => {
   *     console.log('Stream publishMessage() successful, message SID:', message.sid);
   *   })
   *   .catch((error) => {
   *     console.error('Stream publishMessage() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(pureObject)
  public async publishMessage(data: object): Promise<SyncStreamMessage> {
    this.ensureNotClosed();
    return this.syncStreamImpl.publishMessage(data);
  }

  /**
   * Update the time-to-live of the stream.
   * @param ttl Specifies the TTL in seconds after which the stream is subject to automatic deletion. The value 0 means infinity.
   * @return A promise that resolves after the TTL update was successful.
   * @example
   * ```typescript
   * stream.setTtl(3600)
   *   .then(() => {
   *     console.log('Stream setTtl() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Stream setTtl() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(nonNegativeInteger)
  public async setTtl(ttl: number): Promise<void> {
    this.ensureNotClosed();
    return this.syncStreamImpl.setTtl(ttl);
  }

  /**
   * Permanently delete this Stream.
   * @return A promise which resolves after the Stream is successfully deleted.
   * @example
   * ```typescript
   * stream.removeStream()
   *   .then(() => {
   *     console.log('Stream removeStream() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Stream removeStream() failed', error);
   *   });
   * ```
   */
  public async removeStream() {
    this.ensureNotClosed();
    return this.syncStreamImpl.removeStream();
  }

  /**
   * Conclude work with the stream instance and remove all event listeners attached to it.
   * Any subsequent operation on this object will be rejected with error.
   * Other local copies of this stream will continue operating and receiving events normally.
   * @example
   * ```typescript
   * stream.close();
   * ```
   */
  public close(): void {
    super.close();
    this.syncStreamImpl.detach(this.listenerUuid);
  }

}

export { SyncStream, SyncStreamImpl };
export default SyncStream;
