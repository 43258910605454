import { EventEmitter } from 'events';
import { v4 as uuidv4 } from 'uuid';

abstract class Closeable extends EventEmitter {

  private readonly uuid: string;
  private closed: boolean;

  constructor() {
    super();
    this.closed = false;
    this.uuid = uuidv4();
  }

  get listenerUuid(): string {
    return this.uuid;
  }

  protected close() {
    this.removeAllListeners();
    this.closed = true;
  }

  protected ensureNotClosed() {
    if (this.closed) {
      throw new Error('Invalid operation on closed object');
    }
  }

}
export { Closeable };
export default Closeable;