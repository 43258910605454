import { deepClone, isPositiveInteger, validatePageSize } from './utils/sanitize';
import { UriBuilder } from './utils/uri';
import { SyncError } from './utils/syncerror';
import log from './utils/logger';

import { SyncEntity, EntityServices, RemovalHandler } from './entity';
import { SyncMapItemDescriptor, SyncMapItem } from './syncmapitem';
import { Paginator } from './paginator';
import { Cache } from './cache';

import { Mutator } from './interfaces/mutator';
import { NamespacedMergingQueue } from './mergingqueue';
import { Closeable } from './closeable';
import { validateTypesAsync, nonNegativeInteger, pureObject, objectSchema, custom } from '@twilio/declarative-type-validator';

export interface SyncMapServices extends EntityServices {
}

export interface SyncMapDescriptor {
  sid: string;
  url: string;
  revision: string;
  last_event_id: number;
  links: any;
  unique_name: string;
  date_updated: Date;
  date_expires: string;

  items?: SyncMapItemDescriptor[];
}

interface PutItemResult {
  item: SyncMapItemDescriptor;
  added: boolean;
}

/**
 * Map item metadata.
 */
interface SyncMapItemMetadata {
  /**
   * Specifies the time-to-live in seconds after which the map item is subject to automatic deletion.
   * The value 0 means infinity.
   */
  ttl?: number;
}

/**
 * Map item query options.
 */
interface SyncMapItemQueryOptions {
  /**
   * SyncMapItem key, which should be used as the offset. If undefined, starts from the beginning or end depending
   * on queryOptions.order.
   */
  from?: string;

  /**
   * Result page size.
   */
  pageSize?: number | string;

  /**
   * Lexicographical order of results.
   */
  order?: 'asc' | 'desc';

  key?: string;

  limit?: number;
}

class SyncMapImpl extends SyncEntity {
  private readonly descriptor: SyncMapDescriptor;
  private readonly updateMergingQueue: NamespacedMergingQueue<string, SyncMapItemMetadata, SyncMapItem>;
  private readonly cache: Cache<string, SyncMapItem>;

  /**
   * @private
   */
  constructor(services: SyncMapServices, descriptor: SyncMapDescriptor, removalHandler: RemovalHandler) {
    super(services, removalHandler);
    const updateRequestReducer = (acc, input) => (typeof input.ttl === 'number') ? {ttl: input.ttl}
      : acc;
    this.updateMergingQueue = new NamespacedMergingQueue<string, SyncMapItemMetadata, SyncMapItem>(updateRequestReducer);
    this.cache = new Cache<string, SyncMapItem>();
    this.descriptor = descriptor;
    this.descriptor.date_updated = new Date(this.descriptor.date_updated);

    if (descriptor.items) {
      descriptor.items.forEach(itemDescriptor => {
        itemDescriptor.date_updated = new Date(itemDescriptor.date_updated);
        this.cache.store(itemDescriptor.key, new SyncMapItem(itemDescriptor), itemDescriptor.last_event_id);
      });
    }
  }

  // private props
  get uri(): string {
    return this.descriptor.url;
  }

  get links(): any {
    return this.descriptor.links;
  }

  get revision(): string {
    return this.descriptor.revision;
  }

  get lastEventId(): number {
    return this.descriptor.last_event_id;
  }

  get dateExpires(): string {
    return this.descriptor.date_expires;
  }

  static get type() {
    return 'map';
  }

  get type() {
    return 'map';
  }

  // below properties are specific to Insights only
  get indexName(): string {
    return undefined;
  }

  get queryString(): string {
    return undefined;
  }

  // public props, documented along with class description
  get sid() {
    return this.descriptor.sid;
  }

  get uniqueName() {
    return this.descriptor.unique_name || null;
  }

  get dateUpdated(): Date {
    return this.descriptor.date_updated;
  }

  public async set(key: string, value: Object, itemMetadataUpdates?: SyncMapItemMetadata) {
    const input: SyncMapItemMetadata = itemMetadataUpdates || {};
    return this.updateMergingQueue.squashAndAdd(key, input, (input) => this._putItemUnconditionally(key, value, input.ttl));
  }

  public async get(key: string): Promise<SyncMapItem> {
    if (key === null || key === undefined) {
      throw new SyncError('SyncMapItem key may not be empty', 400, 54209);
    }

    if (this.cache.has(key)) {
      return this.cache.get(key);
    } else {
      return this._getItemFromServer(key);
    }
  }

  private async _getItemFromServer(key: string): Promise<SyncMapItem> {
    let result = await this.queryItems({key: key});
    if (result.items.length < 1) {
      throw new SyncError(`The specified Map Item does not exist`, 404, 54201);
    } else {
      return result.items[0];
    }
  }

  public async mutate(key: string, mutator: Mutator, itemMetadataUpdates?: SyncMapItemMetadata): Promise<SyncMapItem> {
    const input: SyncMapItemMetadata = itemMetadataUpdates || {};
    return this.updateMergingQueue.add(key, input, (input) => this._putItemWithIfMatch(key, mutator, input.ttl));
  }

  public async update(key: string, obj: Object, itemMetadataUpdates?: SyncMapItemMetadata): Promise<SyncMapItem> {
    return this.mutate(key, remote => Object.assign(remote, obj), itemMetadataUpdates);
  }

  private async _putItemUnconditionally(key: string, data: Object, ttl: number): Promise<SyncMapItem> {
    const result: PutItemResult = await this._putItemToServer(key, data, undefined, ttl);
    const item = result.item;
    this._handleItemMutated(item.key, item.url, item.last_event_id, item.revision, item.data,
      item.date_updated, item.date_expires, result.added, false);
    return this.cache.get(item.key);
  }

  private async _putItemWithIfMatch(key: string, mutatorFunction: Mutator, ttl: number): Promise<SyncMapItem> {
    const currentItem = await this.get(key)
      .catch(error => {
        if (error.status === 404) {
          // PUT /Items/myKey with `If-Match: -1` acts as "put if not exists"
          return new SyncMapItem({key: key, data: {}, last_event_id: -1, revision: '-1', url: null, date_updated: null, date_expires: null});
        } else {
          throw error;
        }
      });
    let data = mutatorFunction(deepClone(currentItem.data));
    if (data) {
      let ifMatch = currentItem.revision;
      try {
        const result: PutItemResult = await this._putItemToServer(key, data, ifMatch, ttl);
        const item = result.item;
        this._handleItemMutated(item.key, item.url, item.last_event_id, item.revision, item.data,
          item.date_updated, item.date_expires, result.added, false);
        return this.cache.get(item.key);
      } catch (error) {
        if (error.status === 412) {
          await this._getItemFromServer(key);
          return this._putItemWithIfMatch(key, mutatorFunction, ttl);
        } else {
          throw error;
        }
      }
    } else {
      return currentItem;
    }
  }

  private async _putItemToServer(key: string, data: Object, ifMatch: string, ttl: number): Promise<PutItemResult> {
    const url = new UriBuilder(this.links.items).pathSegment(key).build();
    const requestBody: any = {data};

    if (ttl !== undefined) {
      requestBody.ttl = ttl;
    }

    try {
      const response = await this.services.network.put(url, requestBody, ifMatch);
      const mapItemDescriptor = response.body;
      mapItemDescriptor.data = data; // The server does not return the data in the response
      mapItemDescriptor.date_updated = new Date(mapItemDescriptor.date_updated);
      const added = response.status.code === 201;
      return {added, item: mapItemDescriptor};
    } catch (error) {
      if (error.status === 404) {
        this.onRemoved(false);
      }
      throw error;
    }
  }

  async remove(key: string): Promise<void> {
    const item = await this.get(key);
    const previousItemData = deepClone(item.data);
    const response = await this.services.network.delete(item.uri);
    this._handleItemRemoved(key, response.body.last_event_id, previousItemData, new Date(response.body.date_updated), false);
  }

  /**
   * @private
   */
  protected async queryItems(args?): Promise<Paginator<SyncMapItem>> {
    args = args || {};
    const uri = new UriBuilder(this.links.items)
      .queryParam('From', args.from)
      .queryParam('PageSize', args.limit)
      .queryParam('Key', args.key)
      .queryParam('PageToken', args.pageToken)
      .queryParam('Order', args.order)
      .build();

    let response = await this.services.network.get(uri);
    let items = response.body.items.map(el => {
      el.date_updated = new Date(el.date_updated);
      let itemInCache = this.cache.get(el.key);
      if (itemInCache) {
        this._handleItemMutated(el.key, el.url, el.last_event_id, el.revision, el.data, el.date_updated, el.date_expires, false, true);
      } else {
        this.cache.store(el.key, new SyncMapItem(el), el.last_event_id);
      }
      return this.cache.get(el.key);
    });
    const meta = response.body.meta;
    return new Paginator<SyncMapItem>(items
      , pageToken => this.queryItems({pageToken})
      , meta.previous_token
      , meta.next_token);
  }

  async getItems(args?: any): Promise<Paginator<SyncMapItem>> {
    args = args || {};
    validatePageSize(args.pageSize);
    args.limit = args.pageSize || args.limit || 50;
    args.order = args.order || 'asc';
    return this.queryItems(args);
  }

  private shouldIgnoreEvent(key: string, eventId: number) {
    return this.cache.isKnown(key, eventId);
  }

  /**
   * Handle update from the server
   * @private
   */
  _update(update, isStrictlyOrdered: boolean): void {
    update.date_created = new Date(update.date_created);
    switch (update.type) {
      case 'map_item_added':
      case 'map_item_updated': {
        this._handleItemMutated(
          update.item_key,
          update.item_url,
          update.id,
          update.item_revision,
          update.item_data,
          update.date_created,
          undefined, // orchestration events do not include date_expires
          update.type === 'map_item_added',
          true);
      }
        break;
      case 'map_item_removed': {
        this._handleItemRemoved(update.item_key, update.id, update.item_data, update.date_created, true);
      }
        break;
      case 'map_removed': {
        this.onRemoved(false);
      }
        break;
    }

    if (isStrictlyOrdered) {
      this._advanceLastEventId(update.id, update.map_revision);
    }
  }

  _advanceLastEventId(eventId: number, revision?: string): void {
    if (this.lastEventId < eventId) {
      this.descriptor.last_event_id = eventId;
      if (revision) {
        this.descriptor.revision = revision;
      }
    }
  }

  private _updateRootDateUpdated(dateUpdated: Date) {
    if (!this.descriptor.date_updated || dateUpdated.getTime() > this.descriptor.date_updated.getTime()) {
      this.descriptor.date_updated = dateUpdated;
      this.services.storage.update(this.type, this.sid, this.uniqueName, {date_updated: dateUpdated});
    }
  }

  private _handleItemMutated(
    key: string,
    url: string,
    lastEventId: number,
    revision: string,
    data: Object,
    dateUpdated: Date,
    dateExpires: string,
    added: boolean,
    remote: boolean
  ): void {
    if (this.shouldIgnoreEvent(key, lastEventId)) {
      log.trace('SyncMapItem ', key, ' update skipped, current:', this.lastEventId, ', remote:', lastEventId);
      return;
    }

    this._updateRootDateUpdated(dateUpdated);
    const item = this.cache.get(key);

    if (!item) {
      const newItem = new SyncMapItem({
        key: key,
        url,
        last_event_id: lastEventId,
        revision,
        data,
        date_updated: dateUpdated,
        date_expires: dateExpires,
      });

      this.cache.store(key, newItem, lastEventId);
      this.emitItemMutationEvent(newItem, remote, added);

      return;
    }

    const previousItemData = deepClone(item.data);
    item.update(lastEventId, revision, data, dateUpdated);
    this.cache.store(key, item, lastEventId);

    if (dateExpires !== undefined) {
      item.updateDateExpires(dateExpires);
    }

    this.emitItemMutationEvent(item, remote, false, previousItemData);
  }

  private emitItemMutationEvent(item: SyncMapItem, remote: boolean, added: boolean, previousItemData: null | Object = null): void {
    const eventName = added ? 'itemAdded' : 'itemUpdated';
    const args: any = { item, isLocal: !remote };

    if (!added) {
      args.previousItemData = previousItemData;
    }

    this.broadcastEventToListeners(eventName, args);
  }

  /**
   * @private
   */
  protected _handleItemRemoved(key, eventId, oldData, dateUpdated: Date, remote: boolean) {
    this._updateRootDateUpdated(dateUpdated);
    this.cache.delete(key, eventId);
    this.broadcastEventToListeners('itemRemoved', {key: key, isLocal: !remote, previousItemData: oldData});
  }

  protected onRemoved(locally: boolean) {
    this._unsubscribe();
    this.removalHandler(this.type, this.sid, this.uniqueName);
    this.broadcastEventToListeners('removed', {isLocal: locally});
  }

  public async setTtl(ttl: number): Promise<void> {
    try {
      const requestBody = {ttl};
      const response = await this.services.network.post(this.uri, requestBody);
      this.descriptor.date_expires = response.body.date_expires;
    } catch (error) {
      if (error.status === 404) {
        this.onRemoved(false);
      }
      throw error;
    }
  }

  public async setItemTtl(key: string, ttl: number): Promise<void> {
    let existingItem = await this.get(key);
    const requestBody = {ttl};
    const response = await this.services.network.post(existingItem.uri, requestBody);
    existingItem.updateDateExpires(response.body.date_expires);
  }

  async removeMap() {
    await this.services.network.delete(this.uri);
    this.onRemoved(true);
  }
}

/**
 * Represents a Sync map, which is a data structure that stores an unordered set of key-value pairs.
 * Use the {@link SyncClient.map} method to obtain a reference to a Sync map.
 * Information about rate limits can be found [here](https://www.twilio.com/docs/sync/limits).
 */
class SyncMap extends Closeable {
  private readonly syncMapImpl: SyncMapImpl;

  // private props
  get uri(): string {
    return this.syncMapImpl.uri;
  }

  get links(): any {
    return this.syncMapImpl.links;
  }

  get revision(): string {
    return this.syncMapImpl.revision;
  }

  get lastEventId(): number {
    return this.syncMapImpl.lastEventId;
  }

  get dateExpires(): string {
    return this.syncMapImpl.dateExpires;
  }

  static get type() {
    return SyncMapImpl.type;
  }

  get type() {
    return SyncMapImpl.type;
  }

  /**
   * An immutable identifier (a SID) assigned by the system on creation.
   */
  get sid() {
    return this.syncMapImpl.sid;
  }

  /**
   * An optional immutable identifier that may be assigned by the
   * programmer to this map on creation. Unique among other Maps.
   */
  get uniqueName() {
    return this.syncMapImpl.uniqueName;
  }

  /**
   * Date when the map was last updated.
   */
  get dateUpdated(): Date {
    return this.syncMapImpl.dateUpdated;
  }

  /**
   * @internal
   */
  constructor(syncMapImpl: SyncMapImpl) {
    super();
    this.syncMapImpl = syncMapImpl;
    this.syncMapImpl.attach(this);
  }

  /**
   * Fired when a new item appears in the map, regardless of whether its creator was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * {@link SyncMapItem} `item` - added item
   *     * boolean `isLocal` - equals true if the item was added by a local actor, false otherwise
   * @example
   * ```typescript
   * map.on('itemAdded', (args) => {
   *   console.log(`Map item ${args.item.key} was added`);
   *   console.log('args.item.data:', args.item.data);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly itemAdded = 'itemAdded';

  /**
   * Fired when a map item is updated (not added or removed, but changed), regardless of whether the updater was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * {@link SyncMapItem} `item` - updated item
   *     * boolean `isLocal` - equals true if the item was updated by a local actor, false otherwise
   *     * object `previousItemData` - contains a snapshot of the item data before the update
   * @example
   * ```typescript
   * map.on('itemUpdated', (args) => {
   *   console.log(`Map item ${args.item.key} was updated`);
   *   console.log('args.item.data:', args.item.data);
   *   console.log('args.isLocal:', args.isLocal);
   *   console.log('args.previousItemData:', args.previousItemData);
   * });
   * ```
   * @event
   */
  static readonly itemUpdated = 'itemUpdated';

  /**
   * Fired when a map item is removed, regardless of whether the remover was local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * string `key` - the key of the removed item
   *     * boolean `isLocal` - equals true if the item was added by a local actor, false otherwise
   *     * object `previousItemData` - contains a snapshot of the item data before removal
   * @example
   * ```typescript
   * map.on('itemRemoved', (args) => {
   *   console.log(`Map item ${args.key} was removed`);
   *   console.log('args.previousItemData:', args.previousItemData);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly itemRemoved = 'itemRemoved';

  /**
   * Fired when a map is deleted entirely, by any actor local or remote.
   *
   * Parameters:
   * 1. object `args` - info object provided with the event. It has the following properties:
   *     * boolean `isLocal` - equals true if the map was removed by a local actor, false otherwise
   * @example
   * ```typescript
   * map.on('removed', (args) => {
   *   console.log(`Map ${map.sid} was removed`);
   *   console.log('args.isLocal:', args.isLocal);
   * });
   * ```
   * @event
   */
  static readonly removed = 'removed';

  /**
   * Add a new item to the map with the given key-value pair. Overwrites any data that might already exist with that key.
   * @param key Unique item identifier.
   * @param data Data to be set.
   * @param itemMetadataUpdates New item metadata.
   * @return Newly added item, or modified one if already exists, with the latest known data.
   * @example
   * ```typescript
   * map.set('myKey', { name: 'John Smith' }, { ttl: 86400 })
   *   .then((item) => {
   *     console.log('Map SyncMapItem set() successful, item data:', item.data);
   *   })
   *   .catch((error) => {
   *     console.error('Map SyncMapItem set() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    'string',
    pureObject,
    [
      'undefined',
      objectSchema('item metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )
  public async set(key: string, data: Object, itemMetadataUpdates?: SyncMapItemMetadata) {
    this.ensureNotClosed();
    return this.syncMapImpl.set(key, data, itemMetadataUpdates);
  }

  /**
   * Retrieve an item by key.
   * @param key Identifies the desired item.
   * @return A promise that resolves when the item has been fetched.
   * This promise will be rejected if item was not found.
   * @example
   * ```typescript
   * map.get('myKey')
   *   .then((item) => {
   *     console.log('Map SyncMapItem get() successful, item data:', item.data)
   *   })
   *   .catch((error) => {
   *     console.error('Map SyncMapItem get() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync('string')
  public async get(key: string): Promise<SyncMapItem> {
    this.ensureNotClosed();
    return this.syncMapImpl.get(key);
  }

  /**
   * Schedules a modification to this Map SyncMapItem that will apply a mutation function.
   * If no SyncMapItem with the given key exists, it will first be created, having the default data (`{}`).
   * @param key Selects the map item to be mutated.
   * @param mutator A function that outputs a new data based on the existing data.
   * May be called multiple times, particularly if this Map SyncMapItem is modified concurrently by remote code.
   * If the mutation ultimately succeeds, the Map SyncMapItem will have made the particular transition described
   * by this function.
   * @param itemMetadataUpdates New item metadata.
   * @return Resolves with the most recent item state, the output of a successful
   * mutation or a state that prompted graceful cancellation (mutator returned `null`).
   * @example
   * ```typescript
   * const mutatorFunction = (currentData) => {
   *     currentData.viewCount = (currentData.viewCount || 0) + 1;
   *     return currentData;
   * };
   * map.mutate('myKey', mutatorFunction, { ttl: 86400 })
   *   .then((item) => {
   *     console.log('Map SyncMapItem mutate() successful, new data:', item.data)
   *   })
   *   .catch((error) => {
   *     console.error('Map SyncMapItem mutate() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    'string',
    'function',
    [
      'undefined',
      objectSchema('item metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )
  public async mutate(key: string, mutator: Mutator, itemMetadataUpdates?: SyncMapItemMetadata): Promise<SyncMapItem> {
    this.ensureNotClosed();
    return this.syncMapImpl.mutate(key, mutator, itemMetadataUpdates);
  }

  /**
   * Modify a map item by appending new fields (or by overwriting existing ones) with the values from
   * the provided Object. Creates a new item if no item by this key exists, copying all given fields and values
   * into it.
   * This is equivalent to
   * ```typescript
   * map.mutate('myKey', (currentData) => Object.assign(currentData, obj));
   * ```
   * @param key Selects the map item to update.
   * @param obj Specifies the particular (top-level) attributes that will receive new values.
   * @param itemMetadataUpdates New item metadata.
   * @return A promise resolving to the modified item in its new state.
   * @example
   * ```typescript
   * // Say, the Map SyncMapItem (key: `'myKey'`) data is `{ name: 'John Smith' }`
   * map.update('myKey', { age: 34 }, { ttl: 86400 })
   *   .then((item) => {
   *     // Now the Map SyncMapItem data is `{ name: 'John Smith', age: 34 }`
   *     console.log('Map SyncMapItem update() successful, new data:', item.data);
   *   })
   *   .catch((error) => {
   *     console.error('Map SyncMapItem update() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(
    'string',
    pureObject,
    [
      'undefined',
      objectSchema('item metadata', {
        ttl: [nonNegativeInteger, 'undefined']
      })
    ]
  )
  public async update(key: string, obj: Object, itemMetadataUpdates?: SyncMapItemMetadata): Promise<SyncMapItem> {
    this.ensureNotClosed();
    return this.syncMapImpl.update(key, obj, itemMetadataUpdates);
  }

  /**
   * Delete an item, given its key.
   * @param key Selects the item to delete.
   * @return A promise to remove an item.
   * The promise will be rejected if 'key' is undefined or an item was not found.
   * @example
   * ```typescript
   * map.remove('myKey')
   *   .then(() => {
   *     console.log('Map SyncMapItem remove() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Map SyncMapItem remove() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync('string')
  async remove(key: string): Promise<void> {
    this.ensureNotClosed();
    return this.syncMapImpl.remove(key);
  }

  /**
   * Get a complete list of items from the map.
   * Information about the query limits can be found [here](https://www.twilio.com/docs/sync/limits).
   * @param queryOptions Query options.
   * @example
   * ```typescript
   * const pageHandler = (paginator) => {
   *   paginator.items.forEach((item) => {
   *     console.log(`SyncMapItem ${item.key}: `, item.data);
   *   });
   *   return paginator.hasNextPage
   *     ? paginator.nextPage().then(pageHandler)
   *     : null;
   * };
   * map.getItems({ from: 'myKey', order: 'asc' })
   *   .then(pageHandler)
   *   .catch((error) => {
   *     console.error('Map getItems() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync([
    'undefined',
    objectSchema('query options', {
      from: ['string', 'undefined'],
      pageSize: [custom((value) => [isPositiveInteger(value), 'a positive integer']), 'undefined']
    })
  ])
  async getItems(queryOptions?: SyncMapItemQueryOptions): Promise<Paginator<SyncMapItem>> {
    this.ensureNotClosed();
    return this.syncMapImpl.getItems(queryOptions);
  }

  /**
   * Update the time-to-live of the map.
   * @param ttl Specifies the TTL in seconds after which the map is subject to automatic deletion. The value 0 means infinity.
   * @return A promise that resolves after the TTL update was successful.
   * @example
   * ```typescript
   * map.setTtl(3600)
   *   .then(() => {
   *     console.log('Map setTtl() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Map setTtl() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync(nonNegativeInteger)
  public async setTtl(ttl: number): Promise<void> {
    this.ensureNotClosed();
    return this.syncMapImpl.setTtl(ttl);
  }

  /**
   * Update the time-to-live of a map item.
   * @param key SyncMapItem key.
   * @param ttl Specifies the TTL in seconds after which the map item is subject to automatic deletion. The value 0 means infinity.
   * @return A promise that resolves after the TTL update was successful.
   * @example
   * ```typescript
   * map.setItemTtl('myKey', 86400)
   *   .then(() => {
   *     console.log('Map setItemTtl() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Map setItemTtl() failed', error);
   *   });
   * ```
   */
  @validateTypesAsync('string', nonNegativeInteger)
  public async setItemTtl(key: string, ttl: number): Promise<void> {
    this.ensureNotClosed();
    return this.syncMapImpl.setItemTtl(key, ttl);
  }

  /**
   * Delete this map. It will be impossible to restore it.
   * @return A promise that resolves when the map has been deleted.
   * @example
   * ```typescript
   * map.removeMap()
   *   .then(() => {
   *     console.log('Map removeMap() successful');
   *   })
   *   .catch((error) => {
   *     console.error('Map removeMap() failed', error);
   *   });
   * ```
   */
  async removeMap() {
    this.ensureNotClosed();
    await this.syncMapImpl.removeMap();
  }

  /**
   * Conclude work with the map instance and remove all event listeners attached to it.
   * Any subsequent operation on this object will be rejected with error.
   * Other local copies of this map will continue operating and receiving events normally.
   * @example
   * ```typescript
   * map.close();
   * ````
   */
  public close(): void {
    super.close();
    this.syncMapImpl.detach(this.listenerUuid);
  }

}

export { SyncMapItemMetadata, SyncMapItemQueryOptions, SyncMapImpl, SyncMap };

export default SyncMap;
