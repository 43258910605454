import { MessageType, Header } from "../protocol";
import { AbstractMessage } from "./abstractmessage";

export type ReplyType = {
  id: string;
  method?: MessageType;
  payload_type?: string;
  status: {
    status: string;
    code: number;
    errorCode?: number;
    description?: string;
  };
  header: Header;
  body: string;
};

class Reply extends AbstractMessage {
  public readonly method = "reply";
  public readonly payload_type = "application/json";
  public readonly status = { code: 200, status: "OK" };

  public readonly header;
  public readonly body;

  constructor(id: string) {
    super(id);
  }
}

export { Reply };
