/* eslint-disable @typescript-eslint/no-explicit-any */

import { EventEmitter } from "events";

export class ReplayEventEmitter<
  Events extends Record<string | symbol, (...args: any[]) => void>
> extends EventEmitter {
  private eventHistory: Map<string | symbol, any[]> = new Map();

  public constructor() {
    super();
  }

  public on<E extends Extract<keyof Events, string>>(
    event: E,
    listener: Events[E]
  ): this {
    return super.on(event, listener);
  }

  public once<E extends Extract<keyof Events, string>>(
    event: E,
    listener: Events[E]
  ): this {
    return super.once(event, listener);
  }

  public off<E extends Extract<keyof Events, string>>(
    event: E,
    listener: Events[E]
  ): this {
    return super.off(event, listener);
  }

  public emit<E extends Extract<keyof Events, string>>(
    event: E,
    ...args: Parameters<Events[E]>
  ): boolean {
    this.eventHistory.set(event, args);

    return super.emit(event, ...args);
  }

  public addListener<E extends Extract<keyof Events, string>>(
    event: E,
    listener: Events[E]
  ): this {
    return super.addListener(event, listener);
  }

  public removeListener<E extends Extract<keyof Events, string>>(
    event: E,
    listener: Events[E]
  ): this {
    return super.removeListener(event, listener);
  }

  public addListenerWithReplay<E extends Extract<keyof Events, string>>(
    event: E,
    listener: Events[E]
  ): this {
    const historyEntry = this.eventHistory.get(event);

    if (historyEntry !== undefined) {
      listener(...historyEntry);
    }

    return this.addListener(event, listener);
  }

  public onWithReplay<E extends Extract<keyof Events, string>>(
    event: E,
    listener: Events[E]
  ): this {
    return this.addListenerWithReplay(event, listener);
  }
}
