import { AbstractMessage } from "./abstractmessage";

interface ContinuationTokenStatus {
  reissued: boolean;
  reissue_message: string;
}

class InitReply extends AbstractMessage {
  public readonly continuationToken: string;
  public readonly continuationTokenStatus?: ContinuationTokenStatus;
  public readonly offlineStorage?: string;
  public readonly initRegistrations?: string;
  public readonly debugInfo?: string;
  public readonly confirmedCapabilities: Set<string>;

  constructor(
    id: string,
    continuationToken: string,
    confirmedCapabilities: Set<string>,
    continuationTokenStatus?: ContinuationTokenStatus,
    offlineStorage?: string,
    initRegistrations?: string,
    debugInfo?: string
  ) {
    super(id);
    this.continuationToken = continuationToken;
    this.continuationTokenStatus = continuationTokenStatus;
    this.offlineStorage = offlineStorage;
    this.initRegistrations = initRegistrations;
    this.debugInfo = debugInfo;
    this.confirmedCapabilities = confirmedCapabilities;
  }
}

export { ContinuationTokenStatus, InitReply };
