import SyncError from './syncerror';

/**
 * Deep-clone an object. Note that this does not work on object containing
 * functions.
 * @param {object} obj - the object to deep-clone
 * @return {object}
 */
function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

function validatePageSize(pageSize: number) {
  const validPageSize = pageSize === undefined || isPositiveInteger(pageSize);
  if (!validPageSize) {
    throw new SyncError(`Invalid pageSize parameter. Expected a positive integer, was '${pageSize}'.`, 400, 20007);
  }
}

function validateMode(mode: string) {
  if (!['open_or_create', 'open_existing', 'create_new'].includes(mode)) {
    throw new Error("Invalid open mode. Expected one of { 'create_new', 'open_or_create', 'open_existing' }");
  }
}

function isInteger(number: any) {
  return !isNaN(parseInt(number)) && isFinite(number);
}

function isPositiveInteger(number: any) {
  return isInteger(number) && number > 0;
}

function isNonNegativeInteger(number: any) {
  return isInteger(number) && number >= 0;
}

export { deepClone, validatePageSize, validateMode, isPositiveInteger };
