import { User, UserUpdatedEventArgs, UserUpdateReason } from "../user";
import { Network } from "../services/network";
import { SyncClient } from "twilio-sync";
import { UriBuilder } from "../util";
import { Configuration } from "../configuration";
import { CommandExecutor } from "../command-executor";
import { ReplayEventEmitter } from "@twilio/replay-event-emitter";
import { UserResponse } from "../interfaces/commands/user";

type UsersEvents = {
  userUpdated: (data: {
    user: User;
    updateReasons: UserUpdateReason[];
  }) => void;
  userSubscribed: (user: User) => void;
  userUnsubscribed: (user: User) => void;
};

export interface UsersServices {
  network: Network;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

/**
 * Container for known users
 */
class Users extends ReplayEventEmitter<UsersEvents> {
  private readonly configuration: Configuration;
  private readonly services: UsersServices;

  private subscribedUsers: Map<string, User>;
  private fifoStack: string[];
  public readonly myself: User;

  constructor(
    myself: User,
    configuration: Configuration,
    services: UsersServices
  ) {
    super();

    this.configuration = configuration;
    this.services = services;

    this.fifoStack = [];
    this.myself = myself;
    this.myself.on("updated", (args: UserUpdatedEventArgs) =>
      this.emit("userUpdated", args)
    );
    this.myself.on("userSubscribed", () =>
      this.emit("userSubscribed", this.myself)
    );
    this.myself.on("userUnsubscribed", () => {
      this.emit("userUnsubscribed", this.myself);
      this.myself._ensureFetched();
    });

    this.subscribedUsers = new Map<string, User>();
  }

  private handleUnsubscribeUser(user: User): void {
    if (this.subscribedUsers.has(user.identity)) {
      this.subscribedUsers.delete(user.identity);
    }
    let foundItemIndex = 0;
    const foundItem = this.fifoStack.find((item, index) => {
      if (item == user.identity) {
        foundItemIndex = index;
        return true;
      }
      return false;
    });
    if (foundItem) {
      this.fifoStack.splice(foundItemIndex, 1);
    }
    this.emit("userUnsubscribed", user);
  }

  private handleSubscribeUser(user: User): void {
    if (this.subscribedUsers.has(user.identity)) {
      return;
    }
    if (this.fifoStack.length >= this.configuration.userInfosToSubscribe) {
      const item = this.fifoStack.shift() as string;
      this.subscribedUsers?.get(item)?.unsubscribe();
    }
    this.fifoStack.push(user.identity);
    this.subscribedUsers.set(user.identity, user);
    this.emit("userSubscribed", user);
  }

  /**
   * Gets user, if it's in subscribed list - then return the user object from it,
   * if not - then subscribes and adds user to the FIFO stack
   * @returns {Promise<User>} Fully initialized user
   */
  async getUser(identity = "", entityName = ""): Promise<User> {
    await this.myself._ensureFetched();

    if (identity == this.myself.identity) {
      return this.myself;
    }

    const user = this.subscribedUsers.get(identity);

    if (user) {
      return user;
    }

    entityName = entityName || (await this.getSyncUniqueName(identity));

    const newUser = new User(
      identity,
      entityName,
      this.configuration,
      this.services
    );

    newUser.on("updated", (args: UserUpdatedEventArgs) =>
      this.emit("userUpdated", args)
    );
    newUser.on("userSubscribed", () => this.handleSubscribeUser(newUser));
    newUser.on("userUnsubscribed", () => this.handleUnsubscribeUser(newUser));
    await newUser._ensureFetched();

    return newUser;
  }

  /**
   * @returns {Promise<Array<User>>} returns list of subscribed User objects {@see User}
   */
  async getSubscribedUsers(): Promise<Array<User>> {
    await this.myself._ensureFetched();

    const users = [this.myself];
    this.subscribedUsers.forEach((user) => users.push(user));

    return users;
  }

  /**
   * @returns {Promise<string>} User's sync unique name
   */
  private async getSyncUniqueName(identity: string): Promise<string> {
    const url = new UriBuilder(this.configuration.links.users)
      .path(identity)
      .build();
    const response = await this.services.network.get<UserResponse>(url);
    return response.body?.sync_objects.user_info_map ?? "";
  }
}

export { Users };
