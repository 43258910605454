import { type } from "./rules/type";

export type TypeRule =
  | "undefined"
  | "object"
  | "boolean"
  | "number"
  | "bigint"
  | "string"
  | "symbol"
  | "function"
  | { new (...args: unknown[]): unknown };

export type RuleFunction = (
  value: unknown
) => [boolean, (string | string[])?, string?];

export type RuleDefinition = {
  checks: RuleFunction[];
};

export type RuleArgument =
  | TypeRule
  | RuleDefinition
  | (TypeRule | RuleDefinition)[];

type Decorator = (
  target: unknown,
  propertyKey: string,
  descriptor: PropertyDescriptor
) => void;

export const runtimeTypeValidation = (
  rules: RuleDefinition[][],
  values: unknown[]
): void => {
  if (values.length > rules.length) {
    throw new Error(
      `Expected at most ${rules.length} argument(s), but got ${values.length}`
    );
  }

  while (values.length < rules.length) {
    values.push(undefined);
  }

  for (const [index, value] of values.entries()) {
    const [isValid, received, expected, delimeter] = validateValue(
      rules[index],
      value
    );

    if (isValid) {
      continue;
    }

    const argumentIndex = index + 1;

    throw new Error(
      `Argument ${argumentIndex} is expected to be ${expected}${delimeter} but got ${received}`
    );
  }
};

export const stringifyReceivedType = (value: unknown): string => {
  let receivedType;
  const types = ["undefined", "boolean", "number", "bigint", "string"];

  if (types.includes(typeof value)) {
    receivedType = typeof value === "string" ? `"${value}"` : `${value}`;
  }

  if (typeof value === "object" && value?.constructor?.name !== "Object") {
    receivedType =
      value === null ? "null" : `instance of ${value?.constructor?.name}`;
  }

  if (!receivedType) {
    receivedType = typeof value;
  }

  return receivedType;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const validateConstructorTypes = (
  ...args: RuleArgument[]
): (<T extends new (...args: any[]) => any>(ctor: T) => T) => {
  const finalRuleSet = convertRuleArguments(args);

  return <T extends { new (...args: any[]): any }>(ctor: T): T => {
    return class extends ctor {
      constructor(...args: any[]) {
        runtimeTypeValidation(finalRuleSet, args);
        super(...args);
      }
    };
  };
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const validateTypes = (...args: RuleArgument[]): Decorator => {
  const finalRuleSet = convertRuleArguments(args);

  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    if (typeof descriptor.value !== "function") {
      throw new Error(
        "The validateTypes decorator can only be applied to methods"
      );
    }

    const originalMethod = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      runtimeTypeValidation(finalRuleSet, args);
      return originalMethod.apply(this, args);
    };
  };
};

export const validateTypesAsync = (...args: RuleArgument[]): Decorator => {
  const finalRuleSet = convertRuleArguments(args);

  return (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) => {
    if (typeof descriptor.value !== "function") {
      throw new Error(
        "The validateTypesAsync decorator can only be applied to methods"
      );
    }

    const originalMethod = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      try {
        runtimeTypeValidation(finalRuleSet, args);
      } catch (e) {
        return Promise.reject<Error>(e);
      }

      return originalMethod.apply(this, args);
    };
  };
};

export const convertRuleArguments = (
  args: RuleArgument[]
): RuleDefinition[][] => {
  const finalRuleDefinitionSet: RuleDefinition[][] = [];

  for (const arg of args) {
    finalRuleDefinitionSet.push(convertRuleArgument(arg));
  }

  return finalRuleDefinitionSet;
};

export const convertRuleArgument = (arg: RuleArgument): RuleDefinition[] => {
  const finalArgumentRuleDefinitions: RuleDefinition[] = [];
  const declaredRules = Array.isArray(arg) ? arg : [arg];

  for (const rule of declaredRules) {
    if (typeof rule === "string" || typeof rule === "function") {
      finalArgumentRuleDefinitions.push(type(rule));
      continue;
    }

    finalArgumentRuleDefinitions.push(rule);
  }

  return finalArgumentRuleDefinitions;
};

export const validateValue = (
  ruleDefinitions: RuleDefinition[],
  value: unknown
): [true] | [false, string, string, string] => {
  let expectedTypes: string[] = [];
  let customReceivedType: string | undefined;
  let isValid = false;

  for (const definition of ruleDefinitions) {
    for (const check of definition.checks) {
      const [checkPassed, typeDescription, receivedType] = check(value);
      isValid = isValid || checkPassed;

      if (!customReceivedType && receivedType) {
        customReceivedType = receivedType;
      }

      if (typeDescription) {
        expectedTypes =
          typeof typeDescription === "string"
            ? [...expectedTypes, typeDescription]
            : [...expectedTypes, ...typeDescription];
      }
    }
  }

  if (isValid) {
    return [true];
  }

  const receivedType = customReceivedType || stringifyReceivedType(value);
  const lastIndex = expectedTypes.length - 1;
  const expectedTypesString =
    lastIndex > 0
      ? `${expectedTypes.slice(0, lastIndex).join(", ")} or ${
          expectedTypes[lastIndex]
        }`
      : expectedTypes.join(", ");

  return [false, receivedType, expectedTypesString, lastIndex > 1 ? ";" : ","];
};
