import {
  convertRuleArgument,
  RuleArgument,
  RuleDefinition,
  validateValue,
} from "../type-validator";
import { custom } from "./custom";

export const array = (name: string, rules: RuleArgument): RuleDefinition =>
  custom((value) => {
    if (!Array.isArray(value)) {
      return [false, `an array of ${name}`];
    }

    for (const [key, valueOfKey] of value.entries()) {
      const [isValid, received, expected] = validateValue(
        convertRuleArgument(rules),
        valueOfKey
      );

      if (!isValid) {
        return [
          false,
          `a valid array of ${name} (index ${key} should be ${expected})`,
          `malformed array of ${name} (index ${key} is ${received})`,
        ];
      }
    }

    return [true];
  });
