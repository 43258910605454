import * as platform from 'platform';

class ClientInfo {
  sdk: string;
  sdkVer: string;
  os: string;
  osVer: string;
  pl: string;
  plVer: string;

  constructor(version: string) {
    this.sdk = 'js';
    this.sdkVer = version;
    this.os = platform.os.family;
    this.osVer = platform.os.version;
    this.pl = platform.name;
    this.plVer = platform.version;
  }
}

export default ClientInfo;
export { ClientInfo };