import type { Header } from "./protocol/protocol";
import { log } from "./logger";

export type ParsedMessageType = {
  method: string;
  header: Header;
  payload: string;
};

function byteLength(s: string): number {
  const escstr = encodeURIComponent(s);
  const binstr = escstr.replace(/%([0-9A-F]{2})/g, (match, p1) =>
    String.fromCharCode(Number("0x" + p1))
  );
  return binstr.length;
}

function stringToUint8Array(s: string): Uint8Array {
  const escstr = encodeURIComponent(s);
  const binstr = escstr.replace(/%([0-9A-F]{2})/g, (match, p1) =>
    String.fromCharCode(Number("0x" + p1))
  );
  const ua = new Uint8Array(binstr.length);
  Array.prototype.forEach.call(binstr, (ch, i) => {
    ua[i] = ch.charCodeAt(0);
  });
  return ua;
}

function uint8ArrayToString(ua: Uint8Array): string {
  const binstr = Array.prototype.map
    .call(ua, (ch) => String.fromCharCode(ch))
    .join("");
  const escstr = binstr.replace(/(.)/g, (m, p) => {
    let code = p.charCodeAt(0).toString(16).toUpperCase();
    if (code.length < 2) {
      code = "0" + code;
    }
    return "%" + code;
  });
  return decodeURIComponent(escstr);
}

function getJsonObject(array: Uint8Array): Record<string, unknown> {
  return JSON.parse(uint8ArrayToString(array));
}

function getMagic(buffer: Uint8Array) {
  let strMagic = "";
  let idx = 0;
  for (; idx < buffer.length; ++idx) {
    const chr = String.fromCharCode(buffer[idx]);
    strMagic += chr;
    if (chr === "\r") {
      idx += 2;
      break;
    }
  }

  const magics = strMagic.split(" ");
  return {
    size: idx,
    protocol: magics[0],
    version: magics[1],
    headerSize: Number(magics[2]),
  };
}

class Parser {
  static parse(message: ArrayBufferLike): ParsedMessageType | null {
    const fieldMargin = 2;

    const dataView = new Uint8Array(message);
    const magic = getMagic(dataView);
    if (magic.protocol !== "TWILSOCK" || magic.version !== "V3.0") {
      log.error(`unsupported protocol: ${magic.protocol} ver ${magic.version}`);
      //throw new Error('Unsupported protocol');
      //this.fsm.unsupportedProtocol();
      return null;
    }

    let header;
    try {
      header = getJsonObject(
        dataView.subarray(magic.size, magic.size + magic.headerSize)
      );
    } catch (e) {
      log.error("failed to parse message header", e, message);
      //throw new Error('Failed to parse message');
      //this.fsm.protocolError();
      return null;
    }

    log.debug("message received: ", header.method);
    log.trace("message received: ", header);

    let payload;
    if (header.payload_size > 0) {
      const payloadOffset = fieldMargin + magic.size + magic.headerSize;
      const payloadSize = header.payload_size;

      if (
        !header.hasOwnProperty("payload_type") ||
        header.payload_type.indexOf("application/json") === 0
      ) {
        try {
          payload = getJsonObject(
            dataView.subarray(payloadOffset, payloadOffset + payloadSize)
          );
        } catch (e) {
          log.error("failed to parse message body", e, message);
          //this.fsm.protocolError();
          return null;
        }
      } else if (header.payload_type.indexOf("text/plain") === 0) {
        payload = uint8ArrayToString(
          dataView.subarray(payloadOffset, payloadOffset + payloadSize)
        );
      }
    }

    return { method: header.method, header, payload };
  }

  static createPacket(
    header: Partial<Header>,
    payloadString = ""
  ): ArrayBuffer {
    header.payload_size = byteLength(payloadString); // eslint-disable-line camelcase

    const headerString = JSON.stringify(header);
    const magicString = "TWILSOCK V3.0 " + byteLength(headerString);

    log.debug("send request:", magicString + headerString + payloadString);

    const message = stringToUint8Array(
      magicString + "\r\n" + headerString + "\r\n" + payloadString
    );
    return message.buffer as ArrayBuffer;
  }
}

export { Parser };
