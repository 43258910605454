class TokenStorage {
  private initializedFlag = "twilio_twilsock_token_storage";
  private tokenStoragePrefix = "twilio_continuation_token_";
  private static _instance: TokenStorage | null = null;

  constructor() {
    if (!TokenStorage._instance) {
      this.initialize();
      TokenStorage._instance = this;
    }
    return TokenStorage._instance;
  }

  public sessionStorage(): Storage | null {
    try {
      return global["sessionStorage"];
    } catch (err) {
      return null;
    }
  }

  public window(): Window | null {
    try {
      return global["window"];
    } catch (err) {
      return null;
    }
  }

  public storeToken(continuationToken: string, productId: string): void {
    if (this.canStore()) {
      this.sessionStorage.setItem(
        this.getKeyName(productId),
        continuationToken
      );
    }
  }

  public getStoredToken(productId: string): string | null {
    if (!this.canStore()) {
      return null;
    }

    return this.sessionStorage.getItem(this.getKeyName(productId));
  }

  private initialize(): void {
    if (this.canStore()) {
      const flag = this.sessionStorage.getItem(this.initializedFlag);

      // Duplicated tab, cleaning up all stored keys
      if (flag) {
        this.clear();
      }
      this.sessionStorage.setItem(this.initializedFlag, "true");

      // When leaving page or refreshing
      const removeStorageItem = this.sessionStorage.removeItem;
      this.window.addEventListener("unload", () => {
        removeStorageItem(this.initializedFlag);
      });
    }
  }

  public clear(): void {
    if (this.canStore()) {
      const keyToDelete: string[] = [];
      for (let i = 0; i < this.sessionStorage.length; i++) {
        const key = this.sessionStorage.key(i);
        // We manually removed startsWith here due to some problems with babel polyfill setup.
        // Restore it when we figure out what's wrong.
        //if (key.startsWith(TokenStorage.tokenStoragePrefix)) {
        if (key && key.indexOf(this.tokenStoragePrefix) === 0) {
          keyToDelete.push(key);
        }
      }
      const removeStorageItem = this.sessionStorage.removeItem;
      keyToDelete.forEach((key) => removeStorageItem(key));
      removeStorageItem(this.initializedFlag);
    }
  }

  private getKeyName(productId: string) {
    return `${this.tokenStoragePrefix}${productId}`;
  }

  private canStore(): this is TokenStorage & {
    window: Window;
    sessionStorage: Storage;
  } {
    return !!(this.sessionStorage && this.sessionStorage.length && this.window);
  }
}

export default new TokenStorage();
