import { EventEmitter } from "events";
import { Retrier } from "@twilio/operation-retrier";

/**
 * Retrier with backoff override capability
 */
type RetrierOptionsType = {
  min: number;
  max: number;
  initial?: number;
  maxAttemptsCount?: number;
  maxAttemptsTime?: number;
  randomness?: number;
};

class BackoffRetrier extends EventEmitter {
  private readonly options;
  private newBackoff: number | null = null;
  private usedBackoff: number | null = null;

  private retrier: Retrier | null = null;

  public get inProgress(): boolean {
    return !!this.retrier;
  }

  constructor(options: RetrierOptionsType) {
    super();
    this.options = options ? { ...options } : {};
  }

  /**
   * Should be called once per attempt series to start retrier.
   */
  public start(): void {
    if (this.inProgress) {
      throw new Error(
        "Already waiting for next attempt, call finishAttempt(success : boolean) to finish it"
      );
    }
    this.createRetrier();
  }

  /**
   * Should be called to stop retrier entirely.
   */
  public stop(): void {
    this.cleanRetrier();
    this.newBackoff = null;
    this.usedBackoff = null;
  }

  /**
   * Modifies backoff for next attempt.
   * Expected behavior:
   * - If there was no backoff passed previously reschedulling next attempt to given backoff
   * - If previous backoff was longer then ignoring this one.
   * - If previous backoff was shorter then reschedulling with this one.
   * With or without backoff retrier will keep growing normally.
   * @param delay delay of next attempts in ms.
   */
  public modifyBackoff(delay: number): void {
    this.newBackoff = delay;
  }

  /**
   * Mark last emmited attempt as failed, initiating either next of fail if limits were hit.
   */
  public attemptFailed(): void {
    if (!this.inProgress) {
      throw new Error("No attempt is in progress");
    }

    if (this.newBackoff) {
      const shouldUseNewBackoff =
        !this.usedBackoff || this.usedBackoff < this.newBackoff;
      if (shouldUseNewBackoff) {
        this.createRetrier();
      } else {
        this.retrier?.failed(new Error());
      }
    } else {
      this.retrier?.failed(new Error());
    }
  }

  public cancel(): void {
    this.retrier?.cancel();
  }

  private cleanRetrier(): void {
    this.retrier?.removeAllListeners();
    this.retrier?.cancel();
    this.retrier = null;
  }

  private getRetryPolicy(): RetrierOptionsType {
    const clone = { ...this.options };

    if (this.newBackoff) {
      clone.min = this.newBackoff;
      clone.max =
        this.options.max && this.options.max > this.newBackoff
          ? this.options.max
          : this.newBackoff;
    }

    // As we're always skipping first attempt we should add one extra if limit is present
    clone.maxAttemptsCount = this.options.maxAttemptsCount
      ? this.options.maxAttemptsCount + 1
      : undefined;

    return clone;
  }

  private createRetrier(): void {
    this.cleanRetrier();
    const retryPolicy = this.getRetryPolicy();
    this.retrier = new Retrier(retryPolicy);

    this.retrier.once("attempt", () => {
      this.retrier?.on("attempt", () => this.emit("attempt"));
      this.retrier?.failed(new Error("Skipping first attempt"));
    });

    this.retrier.on("failed", (err) => this.emit("failed", err));

    this.usedBackoff = this.newBackoff;
    this.newBackoff = null;

    this.retrier.start();
    // .catch(err => {});
  }
}

export { BackoffRetrier };
