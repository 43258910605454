import { ConversationLimits } from "./interfaces/conversation-limits";
import { SendMediaOptions } from "./conversation";
import { UnsentMessage } from "./unsent-message";
import { JSONValue } from "./types";
import { Messages } from "./data/messages";

/**
 * Message builder. Allows the message to be built and sent via method chaining.
 *
 * Example:
 *
 * ```ts
 * await testConversation.prepareMessage()
 *   .setBody('Hello!')
 *   .setAttributes({foo: 'bar'})
 *   .addMedia(media1)
 *   .addMedia(media2)
 *   .build()
 *   .send();
 * ```
 */
class MessageBuilder {
  private readonly message: UnsentMessage;
  private emailBodies: Map<string, FormData | SendMediaOptions>;
  private emailHistories: Map<string, FormData | SendMediaOptions>;

  /**
   * @internal
   */
  constructor(
    private readonly limits: ConversationLimits,
    messagesEntity: Messages
  ) {
    this.message = new UnsentMessage(messagesEntity);
    this.emailBodies = new Map<string, FormData | SendMediaOptions>();
    this.emailHistories = new Map<string, FormData | SendMediaOptions>();
  }

  /**
   * Sets the message body.
   * @param text Contents of the body.
   */
  setBody(text: string): MessageBuilder {
    this.message.text = text;
    return this;
  }

  /**
   * Sets the message subject.
   * @param subject Contents of the subject.
   */
  setSubject(subject: string): MessageBuilder {
    this.message.emailOptions.subject = subject;
    return this;
  }

  /**
   * Sets the message attributes.
   * @param attributes Message attributes.
   */
  setAttributes(attributes: JSONValue): MessageBuilder {
    this.message.attributes = attributes;
    return this;
  }

  /**
   * Set email body with given MIME-type.
   * @param mimeType Format of the body to set (text/plain or text/html).
   * @param body Body payload in selected format.
   */
  setEmailBody(
    mimeType: string,
    body: FormData | SendMediaOptions
  ): MessageBuilder {
    this.emailBodies.set(mimeType, body);
    return this;
  }

  /**
   * Set email history with given MIME-type.
   * @param mimeType Format of the history to set (text/plain or text/html).
   * @param history History payload in selected format.
   */
  setEmailHistory(
    mimeType: string,
    history: FormData | SendMediaOptions
  ): MessageBuilder {
    this.emailHistories.set(mimeType, history);
    return this;
  }

  /**
   * Adds media to the message.
   * @param payload Media to add.
   */
  addMedia(payload: FormData | SendMediaOptions): MessageBuilder {
    if (typeof FormData === "undefined" && payload instanceof FormData) {
      throw new Error("Could not add FormData content whilst not in a browser");
    }
    if (!(payload instanceof FormData)) {
      const mediaOptions = payload as SendMediaOptions;
      if (!mediaOptions.contentType || !mediaOptions.media) {
        throw new Error(
          "Media content in SendMediaOptions must contain non-empty contentType and media"
        );
      }
    }
    this.message.mediaContent.push(["media", payload]);
    return this;
  }

  /**
   * Builds the message, making it ready to be sent.
   */
  build(): UnsentMessage {
    this.emailBodies.forEach((_, key) => {
      if (!this.limits.emailBodiesAllowedMimeTypes.includes(key)) {
        throw new Error(`Unsupported email body MIME type ${key}`);
      }
    });
    this.emailHistories.forEach((_, key) => {
      if (!this.limits.emailHistoriesAllowedMimeTypes.includes(key)) {
        throw new Error(`Unsupported email history MIME type ${key}`);
      }
    });
    if (
      this.emailBodies.size > this.limits.emailBodiesAllowedMimeTypes.length
    ) {
      throw new Error(
        `Too many email bodies attached to the message (${this.emailBodies.size} > ${this.limits.emailBodiesAllowedMimeTypes.length})`
      );
    }
    if (
      this.emailHistories.size >
      this.limits.emailHistoriesAllowedMimeTypes.length
    ) {
      throw new Error(
        `Too many email histories attached to the message (${this.emailHistories.size} > ${this.limits.emailHistoriesAllowedMimeTypes.length})`
      );
    }

    if (
      this.message.mediaContent.length > this.limits.mediaAttachmentsCountLimit
    ) {
      throw new Error(
        `Too many media attachments in the message (${this.message.mediaContent.length} > ${this.limits.mediaAttachmentsCountLimit})`
      );
    }

    // @todo we don't know the sizes of the attachments in FormData
    // @todo insertion below makes build() method non-repeatable - probably move to UnsentMessage.send() or even sendV2()?

    this.emailBodies.forEach((body) => {
      this.message.mediaContent.push(["body", body]);
    });

    this.emailHistories.forEach((history) => {
      this.message.mediaContent.push(["history", history]);
    });

    return this.message;
  }

  private getPayloadContentType(
    payload: FormData | SendMediaOptions
  ): string | null {
    if (typeof FormData !== "undefined" && payload instanceof FormData) {
      return payload.get("Content-Type") as string;
    }
    return (payload as SendMediaOptions).contentType;
  }
}

export { MessageBuilder };
