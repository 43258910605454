import { TwilsockError } from "./error/twilsockerror";

class OfflineProductStorage {
  public readonly id: string;

  constructor(id: string) {
    this.id = id;
  }

  public static create(productPayload: {
    storage_id: string;
  }): OfflineProductStorage {
    if (productPayload instanceof Object && "storage_id" in productPayload) {
      return new OfflineProductStorage(productPayload.storage_id);
    } else {
      throw new TwilsockError('Field "storage_id" is missing');
    }
  }
}

export { OfflineProductStorage };
