export type RequestFunction<InputType, ReturnType> = (input: InputType) => Promise<ReturnType>;
export type InputReducer<InputType> = (acc: InputType, input: InputType) => InputType;

export interface QueuedRequest<InputType, ReturnType> {
  input: InputType;
  requestFunction: RequestFunction<InputType, ReturnType>;
  resolve: (result: ReturnType) => any;
  reject: (error: any) => any;
}

export class MergingQueue<InputType, ReturnType> {

  private queuedRequests: QueuedRequest<InputType, ReturnType>[] = [];
  private inputMergingFunction: InputReducer<InputType>;
  private isRequestInFlight: boolean = false;

  constructor(inputMergingFunction: InputReducer<InputType>) {
    this.inputMergingFunction = inputMergingFunction;
  }

  public add(input: InputType, requestFunction: RequestFunction<InputType, ReturnType>): Promise<ReturnType> {
    let promise: Promise<ReturnType> = new Promise((resolve, reject) =>
      this.queuedRequests.push({input, requestFunction, resolve, reject})
    );
    this.wakeupQueue();

    return promise;
  }

  public squashAndAdd(input: InputType, requestFunction: RequestFunction<InputType, ReturnType>): Promise<ReturnType> {
    let queueToSquash = this.queuedRequests;
    this.queuedRequests = [];

    let reducedInput;
    if (queueToSquash.length > 0) {
      reducedInput = queueToSquash.map(r => r.input).reduce(this.inputMergingFunction);
      reducedInput = this.inputMergingFunction(reducedInput, input);
    } else {
      reducedInput = input;
    }

    let promise = this.add(reducedInput, requestFunction);

    queueToSquash.forEach(request => promise.then(request.resolve, request.reject));
    return promise;
  }

  public isEmpty(): boolean {
    return this.queuedRequests.length === 0 && !this.isRequestInFlight;
  }

  private wakeupQueue() {
    if (this.queuedRequests.length === 0 || this.isRequestInFlight) {
      return;
    } else {
      let requestToExecute = this.queuedRequests.shift();
      this.isRequestInFlight = true;
      requestToExecute.requestFunction(requestToExecute.input)
        .then(requestToExecute.resolve, requestToExecute.reject)
        .then(__ => {
          this.isRequestInFlight = false;
          this.wakeupQueue();
        });
    }
  }
}

export class NamespacedMergingQueue<K, InputType, ReturnType> {

  private inputReducer: InputReducer<InputType>;
  private queueByNamespaceKey: Map<K, MergingQueue<InputType, ReturnType>> = new Map<K, MergingQueue<InputType, ReturnType>>();

  constructor(inputReducer: InputReducer<InputType>) {
    this.inputReducer = inputReducer;
  }

  public async add(namespaceKey: K, input: InputType, requestFunction: RequestFunction<InputType, ReturnType>): Promise<ReturnType> {
    return this.invokeQueueMethod(namespaceKey, queue => queue.add(input, requestFunction));
  }

  public async squashAndAdd(namespaceKey: K, input: InputType, requestFunction: RequestFunction<InputType, ReturnType>): Promise<ReturnType> {
    return this.invokeQueueMethod(namespaceKey, queue => queue.squashAndAdd(input, requestFunction));
  }

  public async invokeQueueMethod(
    namespaceKey: K,
    queueMethodInvoker: (queue: MergingQueue<InputType, ReturnType>) => Promise<ReturnType>): Promise<ReturnType> {

    if (!this.queueByNamespaceKey.has(namespaceKey)) {
      this.queueByNamespaceKey.set(namespaceKey, new MergingQueue<InputType, ReturnType>(this.inputReducer));
    }
    const queue = this.queueByNamespaceKey.get(namespaceKey);
    const result = queueMethodInvoker(queue);
    if (this.queueByNamespaceKey.get(namespaceKey).isEmpty()) {
      this.queueByNamespaceKey.delete(namespaceKey);
    }
    return result;
  }
}
