
/**
 * Construct URI with query parameters
 */
class UriBuilder {
  base: string;
  args: string[];
  paths: string[];

  constructor(base: string) {
    this.base = base;
    this.args = new Array<string>();
    this.paths = new Array<string>();
  }

  pathSegment(name: string): UriBuilder {
    this.paths.push(encodeURIComponent(name));
    return this;
  }

  queryParam(name: string, value: any): UriBuilder {
    if (typeof value !== 'undefined') {
      this.args.push(encodeURIComponent(name) + '=' + encodeURIComponent(value));
    }
    return this;
  }

  build(): string {
    let result = this.base;
    if (this.paths.length) {
      result += '/' + this.paths.join('/');
    }

    if (this.args.length) {
      result += '?' + this.args.join('&');
    }

    return result;
  }
}

export { UriBuilder };
