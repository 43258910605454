import type { MessageType } from "../protocol";
import { AbstractMessage } from "./abstractmessage";
import { InitRegistration } from "../initregistration";

class Init extends AbstractMessage {
  public readonly method = "init" as MessageType;
  public readonly token: string;
  public readonly continuation_token: string | null;
  public readonly capabilities: string[];
  public readonly metadata?: Record<string, unknown>;
  public readonly registrations?: InitRegistration[] | null;
  public readonly tweaks?: Record<string, unknown> | null;

  constructor(
    token: string,
    continuationToken: string | null,
    metadata?: Record<string, unknown>,
    registrations?: InitRegistration[] | null,
    tweaks?: Record<string, unknown> | null
  ) {
    super();
    this.token = token;
    this.continuation_token = continuationToken;
    this.metadata = metadata;
    this.registrations = registrations;
    this.tweaks = tweaks;
    this.capabilities = ["client_update", "offline_storage", "telemetry.v1"];
  }
}

export { Init };
