import { Configuration } from "../configuration";
import { PacketInterface } from "../packetinterface";
import { Telemetry, TelemetryEvent } from "../protocol/messages/telemetry";
import { log } from "../logger";

class TelemetryEventDescription {
  end?: Date | null;

  constructor(
    readonly title: string,
    readonly details: string,
    readonly start: Date,
    end?: Date | null,
    readonly type?: string,
    readonly id?: string
  ) {
    this.end = end;
  }

  // Prepare telemetry event right before sending it.
  // Convert times to relative.
  public toTelemetryEvent(): TelemetryEvent {
    // Fix dates
    const now = new Date();
    let actualStart = this.start;
    let actualEnd = this.end ? this.end : now;
    if (actualEnd < actualStart) {
      const tmp = actualEnd;
      actualEnd = actualStart;
      actualStart = tmp;
    }

    // Converting dates to relative offset from current moment in ms
    const startOffset = actualStart.getTime() - now.getTime();
    const endOffset = actualEnd.getTime() - now.getTime();

    const result = new TelemetryEvent(
      startOffset,
      endOffset,
      this.title,
      this.details,
      this.id,
      this.type
    );

    return result;
  }
}

enum TelemetryPoint {
  Start,
  End,
}

enum EventSendingLimitation {
  MinEventsPortion, // check for minimal amount of telemetry events, skip sending if less than 100 events are ready to send
  AnyEvents, // send all collected events, do not check for count, skip sending only if no events are ready to send
  AnyEventsIncludingUnfinished, // send all collected (ready to send) as well as unfinished (just started) events
}

class TelemetryTracker {
  // accumulated events count that is big enough to be sent out of schedule (not on timer but on new event registration)
  private readonly minEventsPortionToSend = 50;
  // max events batch size to be sent in a single Telemetry message
  private readonly maxEventsPortionToSend = 100;
  private readonly config: Configuration; // to check confirmed capabilities
  private readonly packetInterface: PacketInterface;
  private pendingEvents = new Map(); // started events: have TelemetryEvent::startTime only
  private readyEvents: TelemetryEventDescription[] = []; // events ready to send
  private hasInitializationFinished = false;

  private _canSendTelemetry = false;

  constructor(config: Configuration, packetInterface: PacketInterface) {
    this.config = config;
    this.packetInterface = packetInterface;
  }

  // Keeping this private prevents the type declaration from being generated properly.
  // Ideally, this should be private.
  get isTelemetryEnabled(): boolean {
    return this.config.confirmedCapabilities.has("telemetry.v1");
  }

  public get canSendTelemetry(): boolean {
    return this._canSendTelemetry && this.isTelemetryEnabled;
  }

  public set canSendTelemetry(enable: boolean) {
    log.debug(
      `TelemetryTracker.canSendTelemetry: ${enable} TelemetryTracker.isTelemetryEnabled: ${this.isTelemetryEnabled}`
    );

    // We want to keep telemetry events added in advance but
    // we need to purge events from previous connection when being disconnected
    if (this._canSendTelemetry && !enable) {
      this.pendingEvents.clear();
      this.readyEvents = [];
    }

    this._canSendTelemetry = enable;

    if (enable) {
      this.sendTelemetry(EventSendingLimitation.AnyEvents);
    }

    if (enable && !this.hasInitializationFinished) {
      this.hasInitializationFinished = true;
    }
  }

  // Add complete event
  public addTelemetryEvent(event: TelemetryEventDescription): void {
    // Allow adding events before initialization.
    if (!this.canSendTelemetry && this.hasInitializationFinished) {
      return;
    }

    this.readyEvents.push(event);
  }

  // Add incomplete event (with either starting or ending time point)
  public addPartialEvent(
    incompleteEvent: TelemetryEventDescription,
    eventKey: string,
    point: TelemetryPoint
  ): void {
    log.debug(
      `Adding ${
        point === TelemetryPoint.Start ? "starting" : "ending"
      } timepoint for '${eventKey}' event`
    );
    const exists = this.pendingEvents.has(eventKey);
    if (point === TelemetryPoint.Start) {
      if (exists) {
        log.debug(`Overwriting starting point for '${eventKey}' event`);
      }
      this.pendingEvents.set(eventKey, incompleteEvent);
    } else {
      if (!exists) {
        log.info(`Could not find started event for '${eventKey}' event`);
        return;
      }
      this.addTelemetryEvent(
        this.merge(this.pendingEvents.get(eventKey), incompleteEvent)
      );
      this.pendingEvents.delete(eventKey);
    }
  }

  public getTelemetryToSend(
    sendingLimit: EventSendingLimitation
  ): TelemetryEventDescription[] {
    if (!this.canSendTelemetry || this.readyEvents.length == 0) {
      return []; // Events are collected but not sent until telemetry is enabled
    }

    if (
      sendingLimit == EventSendingLimitation.MinEventsPortion &&
      this.readyEvents.length < this.minEventsPortionToSend
    ) {
      return [];
    }

    return this.getTelemetryPortion(
      sendingLimit == EventSendingLimitation.AnyEventsIncludingUnfinished
    );
  }

  private getTelemetryPortion(
    includeUnfinished: boolean
  ): TelemetryEventDescription[] {
    const eventsPortionToSend = Math.min(
      this.readyEvents.length,
      this.maxEventsPortionToSend
    );
    const res = this.readyEvents.splice(0, eventsPortionToSend);

    if (includeUnfinished && res.length < this.maxEventsPortionToSend) {
      this.pendingEvents.forEach((value, key) => {
        if (res.length >= this.maxEventsPortionToSend) {
          return; // @fixme does not end the loop early
        }
        const event = this.pendingEvents.get(key);
        this.pendingEvents.delete(key);
        res.push(
          new TelemetryEventDescription(
            `[UNFINISHED] ${event.title}`, // add prefix title to mark unfinished events for CleanSock
            event.details,
            event.start,
            null, // Not ended, on sending will be replaced with now
            event.type,
            event.id
          )
        );
      });
    }

    return res;
  }

  // Merging 2 partial events:
  //   use start.startTime & end.endTime.
  // For other fields,
  //   if there are values in end, use them,
  //   else use values from start.
  private merge(
    start: TelemetryEventDescription,
    end: TelemetryEventDescription
  ): TelemetryEventDescription {
    return new TelemetryEventDescription(
      end.title ? end.title : start.title,
      end.details ? end.details : start.details,
      start.start,
      end.end,
      end.type ? end.type : start.type,
      end.id ? end.id : start.id
    );
  }

  public sendTelemetryIfMinimalPortionCollected(): void {
    this.sendTelemetry(EventSendingLimitation.MinEventsPortion);
  }

  // NB: getTelemetryToSend will return non-empty array only if we have already received initReply
  // and telemetry.v1 capability is enabled there.
  public sendTelemetry(limit: EventSendingLimitation): void {
    const events = this.getTelemetryToSend(limit);

    if (events.length === 0) {
      return; // not enough telemetry data collected
    }

    try {
      this.packetInterface.send(
        new Telemetry(events.map((e) => e.toTelemetryEvent()))
      );
    } catch (err) {
      log.debug(
        `Error while sending ${events.length} telemetry events due to ${err}; they will be resubmitted`
      );
      this.readyEvents = this.readyEvents.concat(events);
    }
  }
}

export {
  TelemetryTracker,
  TelemetryEventDescription,
  TelemetryPoint,
  EventSendingLimitation,
};
