import type { MessageType } from "../protocol";
import { AbstractMessage } from "./abstractmessage";

class Update extends AbstractMessage {
  public readonly method = "update" as MessageType;
  public readonly token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }
}

export { Update };
