class Deferred<T> {
  private _promise: Promise<T>;
  private _resolve;
  private _reject;

  public current!: T;

  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public get promise(): Promise<T> {
    return this._promise;
  }

  update(value: T) {
    this._resolve(value);
  }

  set(value: T) {
    this.current = value;
    this._resolve(value);
  }

  fail(e) {
    this._reject(e);
  }
}

export { Deferred };
