/**
 * Generic SyncLibrary error class
 */
class SyncError extends Error {
  name: string;
  message: string;
  status: number;
  code: number;

  constructor(message: string, status: number = 0, code: number = 0) {
    super();

    this.name = this.constructor.name;
    this.message = `${message} (status: ${status}, code: ${code})`;
    this.status = status;
    this.code = code;
  }
}

class SyncNetworkError extends SyncError {
  body: any;

  constructor(message: string, status: number = 0, code: number = 0, body: any) {
    super(message, status, code);
    this.body = body;
  }
}

export { SyncError, SyncNetworkError };
export default SyncError;
