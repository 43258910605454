import {
  convertRuleArgument,
  RuleArgument,
  RuleDefinition,
  validateValue,
} from "../type-validator";
import { custom } from "./custom";

export const objectSchema = (
  name: string,
  schema: Record<string, RuleArgument>
): RuleDefinition =>
  custom((object) => {
    if (
      typeof object !== "object" ||
      object === null ||
      Array.isArray(object)
    ) {
      return [false, `valid ${name} (should be a pure object)`];
    }

    for (const [key, rules] of Object.entries(schema)) {
      const [isValid, received, expected] = validateValue(
        convertRuleArgument(rules),
        (object as Record<string, unknown>)[key]
      );

      if (!isValid) {
        return [
          false,
          `valid ${name} (key "${key}" should be ${expected})`,
          `malformed ${name} (key "${key}" is ${received})`,
        ];
      }
    }

    return [true];
  });
