import type { MessageType } from "../protocol";
import { AbstractMessage } from "./abstractmessage";

class Close extends AbstractMessage {
  public readonly method = "close" as MessageType;

  constructor() {
    super();
  }
}

export { Close };
