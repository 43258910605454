export interface SyncListItemDescriptor {
  index: number;
  uri: string;
  data: Object;
  revision: string;
  lastEventId: number;
  dateUpdated: Date;
  dateExpires: string;
}

/**
 * Represents an individual element in a Sync list.
 */
class SyncListItem {
  private readonly descriptor: SyncListItemDescriptor;

  /**
   * @param descriptor Item descriptor
   * @internal
   */
  constructor(descriptor: SyncListItemDescriptor) {
    this.descriptor = descriptor;
  }

  get uri(): string {
    return this.descriptor.uri;
  }

  get revision(): string {
    return this.descriptor.revision;
  }

  get lastEventId(): number {
    return this.descriptor.lastEventId;
  }

  /**
   * Date when the list item was last updated.
   */
  get dateUpdated(): Date {
    return this.descriptor.dateUpdated;
  }

  get dateExpires(): string {
    return this.descriptor.dateExpires;
  }

  /**
   * The index, within the containing list, of this item. This index is stable;
   * even if lower-indexed items are removed, this index will remain as is.
   */
  get index(): number {
    return this.descriptor.index;
  }

  /**
   * The contents of the item.
   */
  get data(): Object {
    return this.descriptor.data;
  }

  /**
   * @internal
   */
  update(eventId: number, revision: string, data: Object, dateUpdated: Date): SyncListItem {
    this.descriptor.lastEventId = eventId;
    this.descriptor.revision = revision;
    this.descriptor.data = data;
    this.descriptor.dateUpdated = dateUpdated;
    return this;
  }

  /**
   * @internal
   */
  updateDateExpires(dateExpires: string) {
    this.descriptor.dateExpires = dateExpires;
  }
}

export { SyncListItem };
