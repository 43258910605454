import { SyncEntity } from './entity';

/**
 * Container for entities which are known by the client
 * It's needed for deduplication when client obtain the same object several times
 */
export class EntitiesCache {
  private readonly names: Map<string, string>;
  private readonly entities: Map<string, SyncEntity>;

  constructor() {
    this.names = new Map<string, string>();
    this.entities = new Map<string, SyncEntity>();
  }

  store<T extends SyncEntity>(entity: T): T {
    let stored = this.entities.get(entity.sid);
    if (stored) {
      return stored as T;
    }

    this.entities.set(entity.sid, entity);
    if (entity.uniqueName) {
      this.names.set(entity.type + '::' + entity.uniqueName, entity.sid);
    }
    return entity;
  }

  protected getResolved(id: string, type: string): SyncEntity {
    let resolvedSid = this.names.get(type + '::' + id);
    return resolvedSid ? this.entities.get(resolvedSid) : null;
  }

  get(id: string, type: string): SyncEntity {
    return this.entities.get(id) || this.getResolved(id, type) || null;
  }

  remove(sid: string): void {
    let cached = this.entities.get(sid);
    if (cached) {
      this.entities.delete(sid);
      if (cached.uniqueName) {
        this.names.delete(cached.type + '::' + cached.uniqueName);
      }
    }
  }
}
