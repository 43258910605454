import { EventEmitter } from "events";
import { log } from "./logger";

type ChannelType = "twilsock" | "apn" | "fcm";

type UpdateReason = "token" | "notificationId" | "messageType";

class RegistrationState {
  constructor(
    public token: string = "",
    public notificationId: string = "",
    public messageTypes: Set<string> = new Set<string>()
  ) {}
}

function setDifference<T>(a: Set<T>, b: Set<T>): Array<T> {
  return [
    ...[...a].filter((x) => !b.has(x)),
    ...[...b].filter((x) => !a.has(x)),
  ];
}

function hasDifference(
  a: RegistrationState,
  b: RegistrationState
): [boolean, Set<UpdateReason>] {
  const reasons = new Set<UpdateReason>();
  if (a.notificationId !== b.notificationId) {
    reasons.add("notificationId");
  }

  if (a.token !== b.token) {
    reasons.add("token");
  }

  if (setDifference(a.messageTypes, b.messageTypes).length > 0) {
    reasons.add("messageType");
  }

  return [reasons.size > 0, reasons];
}

abstract class Connector extends EventEmitter {
  protected readonly desiredState: RegistrationState = new RegistrationState();
  protected readonly currentState: RegistrationState = new RegistrationState();
  private _hasActiveAttempt = false; // @todo replace with FSM

  protected constructor(protected readonly channelType: ChannelType) {
    super();
  }

  /**
   * Set desired notification ID for the registration.
   * Call commitChanges() afterwards to commit this change.
   * @param notificationId Notification context ID to register.
   */
  public setNotificationId(notificationId: string): void {
    this.desiredState.notificationId = notificationId;
  }

  /**
   * Return true is this connector is in usable state and should be able to commit changes.
   */
  public isActive(): boolean {
    return this.desiredState.notificationId !== "";
  }

  public subscribe(messageType: string): void {
    if (this.desiredState.messageTypes.has(messageType)) {
      log.debug(
        `message type '${messageType}' for channel ${this.channelType} is already registered`
      );
      return;
    }

    this.desiredState.messageTypes.add(messageType);
  }

  public unsubscribe(messageType: string): void {
    if (!this.desiredState.messageTypes.has(messageType)) {
      return;
    }

    this.desiredState.messageTypes.delete(messageType);
  }

  public updateToken(token: string): void {
    // @todo not entirely correct?
    this.desiredState.token = token;
  }

  /**
   * Perform actual registration after all required changes are settled.
   */
  public async commitChanges(): Promise<void> {
    // if (!this.config.token || this.config.token.length === 0) { // @todo factor desiredState.token here?
    //   log.trace("Can't persist registration: token is not set");
    //   return;
    // }

    if (this._hasActiveAttempt) {
      // Concurrent access violation
      log.error("One registration attempt is already in progress");
      throw new Error("One registration attempt is already in progress");
    }

    const [needToUpdate, reasons] = hasDifference(
      this.desiredState,
      this.currentState
    );
    if (!needToUpdate) {
      // The state did not change - complete successfully!
      return;
    }

    if (!this.currentState.notificationId) {
      reasons.delete("notificationId");
    }

    log.trace(
      `Persisting ${this.channelType} registration`,
      reasons,
      this.desiredState
    );
    try {
      this._hasActiveAttempt = true;

      const stateToPersist: RegistrationState = new RegistrationState();
      stateToPersist.token = this.desiredState.token;
      stateToPersist.notificationId = this.desiredState.notificationId;
      stateToPersist.messageTypes = new Set(this.desiredState.messageTypes);

      if (stateToPersist.messageTypes.size > 0) {
        const persistedState = await this.updateRegistration(
          stateToPersist,
          reasons
        );
        this.currentState.token = persistedState.token;
        this.currentState.notificationId = persistedState.notificationId;
        this.currentState.messageTypes = new Set(persistedState.messageTypes);

        // @todo twilsock emits registered(notificationContextId) when this context is reg'd
        this.emit(
          "stateChanged",
          this.channelType,
          "registered",
          this.currentState
        );
      } else {
        await this.removeRegistration();
        this.currentState.token = stateToPersist.token;
        this.currentState.notificationId = stateToPersist.notificationId;
        this.currentState.messageTypes.clear();

        this.emit(
          "stateChanged",
          this.channelType,
          "unregistered",
          this.currentState
        );
      }
    } catch (e) {
      throw e; // Forward any errors up
    } finally {
      this._hasActiveAttempt = false;
    }
  }

  /**
   * This one goes completely beside the state machine and removes all registrations.
   * Use with caution: if it races with current state machine operations, madness will ensue.
   */
  public abstract sendDeviceRemoveRequest(
    registrationId: string
  ): Promise<void>;

  protected abstract updateRegistration(
    registration: RegistrationState,
    reasons: Set<UpdateReason>
  ): Promise<RegistrationState>;

  protected abstract removeRegistration(): Promise<void>;
}

export { UpdateReason, RegistrationState, ChannelType, Connector };
