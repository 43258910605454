/**
 * Message delivery status.
 */
type DeliveryStatus =
  | "sent"
  | "delivered"
  | "failed"
  | "read"
  | "undelivered"
  | "queued";

interface DetailedDeliveryReceiptDescriptor {
  sid: string;
  message_sid: string;
  conversation_sid: string;
  channel_message_sid: string;
  participant_sid: string;
  status: DeliveryStatus;
  error_code: string | null;
  date_created: string;
  date_updated: string;
}

/**
 * Represents a delivery receipt of a message.
 */
class DetailedDeliveryReceipt {
  /**
   * Unique identifier for the delivery receipt.
   */
  sid: string;

  /**
   * Unique identifier for the message in the conversation.
   */
  messageSid: string;

  /**
   * Unique identifier for the conversation.
   */
  conversationSid: string;

  /**
   * Unique identifier for the `‘channel’` message (e.g., `WAxx` for WhatsApp, `SMxx` for SMS).
   */
  channelMessageSid: string;

  /**
   * Unique identifier for the participant.
   */
  participantSid: string;

  /**
   * Status of the message delivery.
   */
  status: DeliveryStatus;

  /**
   * Numeric error code mapped from Status callback code. Information about the error codes can be found
   * [here](https://www.twilio.com/docs/sms/api/message-resource#delivery-related-errors).
   */
  errorCode: string | 0;

  /**
   * Date this delivery receipt was created on.
   */
  dateCreated: string;

  /**
   * Date this delivery receipt was last updated on.
   */
  dateUpdated: string;

  /**
   * @internal
   */
  constructor(descriptor: DetailedDeliveryReceiptDescriptor) {
    this.sid = descriptor.sid;
    this.messageSid = descriptor.message_sid;
    this.conversationSid = descriptor.conversation_sid;
    this.channelMessageSid = descriptor.channel_message_sid;
    this.participantSid = descriptor.participant_sid;
    this.status = descriptor.status || "queued";
    this.errorCode = descriptor.error_code || 0;
    this.dateCreated = descriptor.date_created;
    this.dateUpdated = descriptor.date_updated;
  }
}

export {
  DetailedDeliveryReceipt,
  DetailedDeliveryReceiptDescriptor,
  DeliveryStatus,
};
