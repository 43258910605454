/**
 * Pagination helper class.
 */
class Paginator<Item> {
  private prevToken: string;
  private nextToken: string;
  private source: any;

  /**
   * Array of elements on current page.
   */
  public readonly items: Item[];

  /**
   * @internal
   */
  constructor(items: any, source: any, prevToken: string, nextToken: string) {
    this.prevToken = prevToken;
    this.nextToken = nextToken;

    this.items = items;
    this.source = source;
  }

  /**
   * Indicates the existence of the next page.
   */
  get hasNextPage(): boolean {
    return !!this.nextToken;
  }

  /**
   * Indicates the existence of the previous page.
   */
  get hasPrevPage(): boolean {
    return !!this.prevToken;
  }

  /**
   * Request next page.
   * Does not modify existing object.
   */
  async nextPage(): Promise<Paginator<Item>> {
    if (!this.hasNextPage) {
      throw new Error('No next page');
    }

    return this.source(this.nextToken);
  }

  /**
   * Request previous page.
   * Does not modify existing object.
   */
  async prevPage(): Promise<Paginator<Item>> {
    if (!this.hasPrevPage) {
      throw new Error('No previous page');
    }

    return this.source(this.prevToken);
  }
}

export { Paginator };
