import { EventEmitter } from "events";

function isDef(value: number | undefined | null): value is number {
  return value !== undefined && value !== null;
}

export interface BackoffOptions {
  initialDelay?: number;
  maxDelay?: number;
  randomisationFactor?: number;
  factor?: number;
}

class Backoff extends EventEmitter {
  private readonly maxDelay: number;
  private readonly initialDelay: number;
  private readonly factor: number;
  private readonly randomisationFactor: number;
  private backoffDelay = 0;
  private nextBackoffDelay = 0;
  private backoffNumber = 0;
  private timeoutID: ReturnType<typeof setTimeout> | null = null;
  private maxNumberOfRetry = -1;

  constructor(options: BackoffOptions) {
    super();
    options = options || {};
    const { initialDelay, maxDelay, randomisationFactor, factor } = options;

    if (isDef(initialDelay) && initialDelay < 1) {
      throw new Error(
        "The initial timeout must be equal to or greater than 1."
      );
    }
    if (isDef(maxDelay) && maxDelay <= 1) {
      throw new Error("The maximal timeout must be greater than 1.");
    }
    if (
      isDef(randomisationFactor) &&
      (randomisationFactor < 0 || randomisationFactor > 1)
    ) {
      throw new Error("The randomisation factor must be between 0 and 1.");
    }
    if (isDef(factor) && factor <= 1) {
      throw new Error(`Exponential factor should be greater than 1.`);
    }

    this.initialDelay = initialDelay || 100;
    this.maxDelay = maxDelay || 10000;
    if (this.maxDelay <= this.initialDelay) {
      throw new Error(
        "The maximal backoff delay must be greater than the initial backoff delay."
      );
    }
    this.randomisationFactor = randomisationFactor || 0;
    this.factor = factor || 2;
    this.reset();
  }

  public static exponential(options: BackoffOptions): Backoff {
    return new Backoff(options);
  }

  public backoff(err?: Error): void {
    if (this.timeoutID == null) {
      if (this.backoffNumber === this.maxNumberOfRetry) {
        this.emit("fail", err);
        this.reset();
      } else {
        this.backoffDelay = this.next();
        this.timeoutID = setTimeout(
          this.onBackoff.bind(this),
          this.backoffDelay
        );
        this.emit("backoff", this.backoffNumber, this.backoffDelay, err);
      }
    }
  }

  public reset(): void {
    this.backoffDelay = 0;
    this.nextBackoffDelay = this.initialDelay;
    this.backoffNumber = 0;
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
    this.timeoutID = null;
  }

  public failAfter(maxNumberOfRetry: number): void {
    if (maxNumberOfRetry <= 0) {
      throw new Error(
        `Expected a maximum number of retry greater than 0 but got ${maxNumberOfRetry}`
      );
    }

    this.maxNumberOfRetry = maxNumberOfRetry;
  }

  next(): number {
    this.backoffDelay = Math.min(this.nextBackoffDelay, this.maxDelay);
    this.nextBackoffDelay = this.backoffDelay * this.factor;
    const randomisationMultiple = 1 + Math.random() * this.randomisationFactor;
    return Math.min(
      this.maxDelay,
      Math.round(this.backoffDelay * randomisationMultiple)
    );
  }

  onBackoff(): void {
    this.timeoutID = null;
    this.emit("ready", this.backoffNumber, this.backoffDelay);
    this.backoffNumber++;
  }
}

export { Backoff };
export default Backoff;
