import { RuleDefinition } from "../type-validator";
import { custom } from "./custom";

export const literal = (...checks: unknown[]): RuleDefinition =>
  custom((value: unknown) => {
    let isValid = false;
    const expectedTypes = [];

    for (const type of checks) {
      isValid = isValid || value === type;
      expectedTypes.push(typeof type === "string" ? `"${type}"` : `${type}`);
    }

    return [isValid, expectedTypes];
  });
