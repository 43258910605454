import type { MessageType, Request } from "../protocol";
import { AbstractMessage } from "./abstractmessage";

class Message extends AbstractMessage {
  public readonly method = "message" as MessageType;
  public readonly active_grant: string;
  public readonly payload_type: string;
  public readonly http_request: Request;

  constructor(grant: string, contentType: string, request: Request) {
    super();

    this.active_grant = grant;
    this.payload_type = contentType;
    this.http_request = request;
  }
}

export { Message };
