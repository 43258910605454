import { Logger } from "./logger";
import { Configuration } from "./configuration";
import { Media, MediaCategory, MediaResponse } from "./media";
import { Transport } from "./services/transport";
import { Network } from "./services/network";
import { version } from "../package.json";
import * as loglevel from "loglevel";
import {
  validateTypesAsync,
  validateTypes,
  nonEmptyString,
  literal,
  pureObject,
  validateConstructorTypes,
} from "@twilio/declarative-type-validator";

const log = Logger.scope("");
//log.setLevel('trace');

export type LogLevel = loglevel.LogLevelDesc | null;

export interface Options {
  region?: string;
  logLevel?: loglevel.LogLevelDesc;
  transport?: Transport;
}

/**
 * @classdesc A Client provides an interface for Media Content Service
 */
@validateConstructorTypes(
  nonEmptyString,
  nonEmptyString,
  [nonEmptyString, literal(null)],
  [pureObject, "undefined"]
)
class Client {
  // eslint-disable-next-line
  private readonly transport: any;
  private options: Options;
  private network: Network;
  private config: Configuration;
  public static readonly version: string = version;

  /**
   * Base URLs must be full URLs with host. If host is not provided it will be generated from a default configuration
   * template using options.region.
   *
   * @param {String} token - Access token
   * @param {String} baseUrl - Base URL for Media Content Service Media resource, i.e. /v1/Services/{serviceSid}/Media
   * @param {String} baseSetUrl - Base URL for Media Content Service MediaSet resource, i.e. /v1/Services/{serviceSid}/MediaSet
   * @param {Client#ClientOptions} [options] - Options to customize the Client
   */
  constructor(
    token: string,
    baseUrl: string,
    baseSetUrl: string | null,
    options: Options = {}
  ) {
    this.options = options;
    this.options.logLevel = this.options.logLevel ?? "silent";
    this.config = new Configuration(token, baseUrl, baseSetUrl, this.options);

    log.setLevel(this.options.logLevel);

    this.options.transport = this.options.transport ?? new Transport();

    this.transport = this.options.transport;
    this.network = new Network(this.config, this.transport);
  }

  /**
   * These options can be passed to Client constructor
   * @typedef {Object} Client#ClientOptions
   * @property {String} [logLevel='silent'] - The level of logging to enable. Valid options
   *   (from strictest to broadest): ['silent', 'error', 'warn', 'info', 'debug', 'trace']
   */

  /**
   * Update the token used for Client operations
   * @param {String} token - The JWT string of the new token
   * @returns {void}
   */
  @validateTypes(nonEmptyString)
  public updateToken(token: string): void {
    log.info("updateToken");
    this.config.updateToken(token);
  }

  /**
   * Gets media from media service
   * @param {String} sid - Media's SID
   * @returns {Promise<Media>}
   */
  @validateTypesAsync(nonEmptyString)
  public async get(sid: string): Promise<Media> {
    const response = await this.network.get(`${this.config.mediaUrl}/${sid}`);
    return new Media(this.config, this.network, response.body);
  }

  /**
   * Posts raw content to media service
   * @param {String} contentType - content type of media
   * @param {String|Buffer|Blob} media - content to post
   * @param {MediaCategory|null} category - category for the media
   * @returns {Promise<Media>}
   */
  public async post(
    contentType: string,
    media: string | Buffer | Blob,
    category: MediaCategory | null,
    filename?: string
  ): Promise<Media> {
    const response = await this.network.post(
      this.config.mediaUrl,
      category ?? "media",
      media,
      contentType,
      filename
    );
    return new Media(this.config, this.network, response.body);
  }

  /**
   * Posts FormData to media service. Can be used only with browser engine's FormData.
   * In non-browser FormData case the method will do promise reject with
   * new TypeError("Posting FormData supported only with browser engine's FormData")
   * @param {FormData} formData - form data to post
   * @param {MediaCategory|null} category - category for the media
   * @returns {Promise<Media>}
   */
  public async postFormData(
    formData: FormData,
    category?: MediaCategory | null
  ): Promise<Media> {
    const response = await this.network.post(
      this.config.mediaUrl,
      category ?? "media",
      formData
    );
    return new Media(this.config, this.network, response.body);
  }

  /**
   * Retrieve information about multiple media SIDs at the same time.
   * @param mediaSids Array of Media SIDs to get information from.
   */
  public async mediaSetGet(mediaSids: string[]): Promise<Media[]> {
    const query = {
      command: "get",
      list: mediaSids.map((sid) => ({ media_sid: sid })),
    };
    const response = await this.network.post(
      `${this.config.mediaSetUrl}`,
      null,
      query,
      "application/json"
    );
    return response.body.map((item) => {
      if (item.code !== 200) {
        throw new Error(
          `Failed to obtain detailed information about Media items (failed SID ${item.media_record.sid})`
        );
      }
      return new Media(this.config, this.network, item.media_record);
    });
  }
}

// Proper renames should happen in index.ts,
// otherwise it might screw up exported TS types information.
export { Client, Media, MediaCategory };
