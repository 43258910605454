class InitRegistration {
  public product: string;
  public type: string;
  public notification_protocol_version: number;
  public message_types: string[]; // It's a Set<string> but TS cannot serialize Sets properly.

  constructor(product: string) {
    this.product = product;
    this.type = "ers";
    this.notification_protocol_version = 0;
    this.message_types = [];
  }

  /*
   * @internal
   *
   * Populate init registrations section in the Twilsock configuration generically with
   * passed-in list of types to register.
   *
   * Used only by the SDK, ignore.
   *
   * @param {string[]} types List of types to add to init registrations in options.
   * @param {object} options Twilsock options object to update. Omit to create a new one.
   */
  public populateInitRegistrations(types: string[]): void {
    const s = new Set<string>(this.message_types);
    // Update options reference in place
    for (const idx in types) {
      s.add(types[idx]);
    }
    this.message_types = Array.from(s);
  }
}

export { InitRegistration };
