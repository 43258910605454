import { TwilsockError } from "./twilsockerror";
import type { Context } from "../protocol/protocol";

class TwilsockUpstreamError extends TwilsockError {
  private readonly status: number;
  private readonly description: string;
  private readonly body?: Context;

  constructor(status: number, description: string, body?: Context) {
    super(description);
    this.status = status;
    this.description = description;
    this.body = body;
  }
}

export { TwilsockUpstreamError };
