import * as log from "loglevel";
import { Transport } from "./services/transport";

const MINIMUM_RETRY_DELAY = 1000;
const MAXIMUM_RETRY_DELAY = 4000;
const MAXIMUM_ATTEMPTS_COUNT = 3;
const RETRY_WHEN_THROTTLED = true;

const regionalMcsHost = (region?: string): string =>
  `https://mcs.${region ?? "us1"}.twilio.com`;

const isFullUrl = (url: string): boolean => url.startsWith("http");

const fullUrl = (partUrl: string, region?: string): string =>
  `${!isFullUrl(partUrl) ? regionalMcsHost(region) : ""}${partUrl}`;

export interface BackoffOverride {
  max: number;
  min: number;
  maxAttemptsCount?: number;
}

export interface McsOptions {
  region?: string;
  retryWhenThrottledOverride?: boolean;
  backoffConfigOverride?: BackoffOverride;
}

export interface Options {
  region?: string;
  logLevel?: log.LogLevelDesc;
  transport?: Transport;
  retryWhenThrottledOverride?: boolean;
  backoffConfigOverride?: BackoffOverride;
  MCS?: McsOptions;
}

class Configuration {
  token: string;
  public readonly retryWhenThrottledOverride: boolean;
  public readonly backoffConfigOverride: BackoffOverride;
  public readonly mediaUrl: string;
  public readonly mediaSetUrl: string;
  private readonly region: string;

  constructor(
    token: string,
    baseUrl: string,
    baseSetUrl: string | null,
    options: Options
  ) {
    const constructorOptions = options.MCS ?? options ?? {};
    this.region = constructorOptions.region ?? options.region ?? 'us1';
    this.mediaUrl = fullUrl(baseUrl, this.region);
    this.mediaSetUrl = baseSetUrl ? fullUrl(baseSetUrl) : `${this.mediaUrl}Set`;
    this.token = token;
    this.retryWhenThrottledOverride =
      constructorOptions.retryWhenThrottledOverride ?? RETRY_WHEN_THROTTLED;
    this.backoffConfigOverride = constructorOptions.backoffConfigOverride ?? Configuration.backoffConfigDefault;
  }

  public static get backoffConfigDefault(): BackoffOverride {
    return {
      min: MINIMUM_RETRY_DELAY,
      max: MAXIMUM_RETRY_DELAY,
      maxAttemptsCount: MAXIMUM_ATTEMPTS_COUNT,
    };
  }

  public static get retryWhenThrottledDefault(): boolean {
    return RETRY_WHEN_THROTTLED;
  }

  public updateToken(token: string): void {
    this.token = token;
  }
}

export { Configuration };
