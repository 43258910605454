import { TwilsockError } from "./twilsockerror";
import type { ReplyType } from "../protocol/messages/reply";

class TwilsockReplyError extends TwilsockError {
  public readonly reply: ReplyType;

  constructor(description: string, reply: ReplyType) {
    super(description);
    this.reply = reply;
  }
}

export { TwilsockReplyError };
