import { AsyncRetrier } from "@twilio/operation-retrier";
import {
  UpdateReason,
  RegistrationState,
  Connector,
  ChannelType,
} from "./connector";
import { TwilsockClient } from "twilsock";
import { log } from "./logger";

const retrierConfig = {
  min: 2000, // ms
  max: 120000, // ms
  randomness: 0.2,
};

export interface RegistrarContext {
  protocolVersion: number;
  productId: string;
  platform: string;
}

/**
 * Manages the registrations on ERS service.
 * Deduplicates registrations and manages them automatically.
 */
class RegistrarConnector extends Connector {
  private registrationId: string | null = null;

  /**
   * Creates new instance of the ERS registrar
   *
   * @param channelType {string} Channel this connector will be servicing.
   * @param context {any} productId, platform, and protocolVersion.
   * @param twilsock {TwilsockClient} connection transport.
   * @param registrarUrl {string}
   */
  constructor(
    channelType: ChannelType,
    private readonly context: RegistrarContext, // context is separate from config because it's not shared with other connectors
    private readonly twilsock: TwilsockClient,
    private readonly registrarUrl: string
  ) {
    super(channelType);
  }

  protected async updateRegistration(
    registration: RegistrationState,
    reasons: Set<UpdateReason>
  ): Promise<RegistrationState> {
    if (reasons.has("notificationId")) {
      await this.removeRegistration();
    }

    if (!registration.notificationId || !registration.notificationId.length) {
      log.error(`No push notification ID for registration`);
      throw new Error(`No push notification ID for registration`); // @todo FSM update to error perhaps
    }

    log.trace("Registering", this.channelType, registration);

    const registrarRequest = {
      endpoint_platform: this.context.platform,
      channel_type: this.channelType,
      version: this.context.protocolVersion.toString(),
      message_types: Array.from(registration.messageTypes),
      data: {
        registration_id: registration.notificationId,
      },
      //ttl: 'PT24H' - This is totally ignored by notify, all bindings use PT1Y ttl.
    };

    const productId = this.context.productId;

    const url = `${this.registrarUrl}?productId=${productId}`;
    const headers = {
      "Content-Type": "application/json",
      // 'X-Twilio-Token': registration.token
    };

    log.trace(`Creating registration for channel ${this.channelType}`);
    try {
      const response = (await new AsyncRetrier(retrierConfig).run(() =>
        this.twilsock.post(url, headers, registrarRequest, productId)
      )) as any;
      this.registrationId = response.body.id;
      log.debug("Registration created: ", response);
    } catch (err) {
      log.error("Registration failed: ", err);
      throw err;
    }

    return registration;
  }

  protected async removeRegistration(): Promise<void> {
    if (!this.registrationId) {
      // No registration ID - no problem, finish successfully.
      return;
    }

    const productId = this.context.productId;

    const url = `${this.registrarUrl}/${this.registrationId}?productId=${productId}`;
    const headers = {
      "Content-Type": "application/json",
      // 'X-Twilio-Token': this.config.token
    };

    log.trace(`Removing registration for ${this.channelType}`);
    try {
      await new AsyncRetrier(
        Object.assign(retrierConfig, { maxAttemptsCount: 3 })
      ).run(() => this.twilsock.delete(url, headers, {}, productId));
      this.registrationId = null;
      this.currentState.notificationId = "";
      log.debug(`Registration removed for ${this.channelType}`);
    } catch (err) {
      log.error("Failed to remove registration ", this.channelType, err);
      throw err;
    }
  }

  public async sendDeviceRemoveRequest(registrationId: string): Promise<void> {
    if (registrationId === "") {
      throw new Error("Empty registration ID");
    }

    const productId = this.context.productId;

    const url = `${this.registrarUrl}?productId=${productId}`;
    const headers = {
      "Content-Type": "application/json",
      // @todo Content-Length??
    };
    const payload = {
      binding_type: this.channelType,
      address: registrationId,
    };

    try {
      log.trace(`Removing old registrations for ${this.channelType}`);
      await new AsyncRetrier(
        Object.assign(retrierConfig, { maxAttemptsCount: 3 })
      ).run(() => this.twilsock.delete(url, headers, payload, productId));
      this.registrationId = null;
      this.currentState.notificationId = "";
      log.debug(`Registration removed for ${this.channelType}`);
    } catch (err) {
      log.error("Failed to remove registration ", this.channelType, err);
      throw err;
    }
  }
}

export { Connector, RegistrarConnector };
