import { Configuration, Storage } from '../interfaces/services';

interface StorageBackend {
  setItem(key: string, value: string): void;

  getItem(key: string): string;

  removeItem(key: string): void;
}

class SessionStorage implements Storage {
  private readonly config: Configuration;
  private readonly storage: StorageBackend;
  private storageId: string;

  constructor(config: Configuration, storage?: StorageBackend) {
    this.config = config;
    this.storageId = null;
    try {
      this.storage = storage || sessionStorage;
    } catch (e) {
    }
  }

  private storageKey(type: string, key: string) {
    return `${this.storageId}::${type}::${key}`;
  }

  private get isReady(): boolean {
    return this.config.sessionStorageEnabled && !!this.storageId;
  }

  updateStorageId(storageId: string) {
    this.storageId = storageId;
  }

  store(type: string, id: string, value: Object) {
    if (!this.isReady) {
      return null;
    }
    return this._store(this.storageKey(type, id), value);
  }

  read(type: string, id: string): Object {
    if (!this.isReady) {
      return null;
    }
    return this._read(this.storageKey(type, id));
  }

  remove(type: string, sid: string, uniqueName: string): void {
    if (!this.isReady) {
      return null;
    }
    try {
      this.storage.removeItem(this.storageKey(type, sid));
      if (uniqueName) {
        this.storage.removeItem(this.storageKey(type, uniqueName));
      }
    } catch (e) {
    }
  }

  update(type: string, sid: string, uniqueName: string, patch: Object): void {
    if (!this.isReady) {
      return null;
    }
    // Currently cache may have root stored twice - by sid and by uniqueName
    // Maybe need to create some index if needed
    this._apply(this.storageKey(type, sid), patch);
    if (uniqueName) {
      this._apply(this.storageKey(type, uniqueName), patch);
    }
  }

  private _store(key: string, value: Object) {
    try {
      this.storage.setItem(key, JSON.stringify(value));
    } catch (e) {
    }
  }

  private _read(key: string): Object {
    try {
      let storedData = this.storage.getItem(key);
      if (storedData) {
        return JSON.parse(storedData);
      }
    } catch (e) {
    }
    return null;
  }

  private _apply(key: string, patch: any): boolean {
    let value = this._read(key);
    if (!value) {
      return false;
    }
    this._store(key, Object.assign(value, patch));
  }
}

export { StorageBackend, SessionStorage };
