import { log } from "../logger";
import { EventEmitter } from "events";
import { v4 as uuid } from "uuid";
import type { Context, MessageType } from "../protocol/protocol";
import { PacketInterface } from "../packetinterface";
import { TwilsockError } from "../error/twilsockerror";

/**
 * Registrations module handles all operations with registration contexts through twilsock.
 * Main role: it automatically refreshes all registrations after reconnect.
 */
class Registrations extends EventEmitter {
  private readonly registrations: Map<string, Context>;
  private readonly registrationsInProgress: Map<string, Set<unknown>>;

  constructor(private readonly transport: PacketInterface) {
    super();

    this.registrations = new Map();
    this.registrationsInProgress = new Map();
  }

  private async putNotificationContext(
    contextId: string,
    context: Context
  ): Promise<void> {
    const header = {
      method: "put_notification_ctx" as MessageType,
      notification_ctx_id: contextId,
    };
    await this.transport.sendWithReply(header, context);
  }

  private async deleteNotificationContext(contextId: string): Promise<void> {
    const message = {
      method: "delete_notification_ctx" as MessageType,
      notification_ctx_id: contextId,
    };
    await this.transport.sendWithReply(message);
  }

  private async updateRegistration(contextId: string, context: Context) {
    log.debug("update registration for context", contextId);

    let registrationAttempts = this.registrationsInProgress.get(contextId);
    if (!registrationAttempts) {
      registrationAttempts = new Set();
      this.registrationsInProgress.set(contextId, registrationAttempts);
    }

    const attemptId = uuid();
    registrationAttempts.add(attemptId);

    try {
      await this.putNotificationContext(contextId, context);

      log.debug("registration attempt succeeded for context", context);
      registrationAttempts.delete(attemptId);
      if (registrationAttempts.size === 0) {
        this.registrationsInProgress.delete(contextId);
        this.emit("registered", contextId);
      }
    } catch (err) {
      log.warn("registration attempt failed for context", context);
      log.debug(err);

      registrationAttempts.delete(attemptId);
      if (registrationAttempts.size === 0) {
        this.registrationsInProgress.delete(contextId);
        this.emit("registrationFailed", contextId, err);
      }
    }
  }

  public async updateRegistrations(): Promise<void> {
    log.trace(`refreshing ${this.registrations.size} registrations`);
    const promises: Promise<void>[] = [];
    this.registrations.forEach((context: Context, id) => {
      promises.push(this.updateRegistration(id, context));
    });
    await Promise.all(promises);
  }

  public async setNotificationsContext(
    contextId: string,
    context: Context
  ): Promise<void> {
    if (!contextId || !context) {
      throw new TwilsockError("Invalid arguments provided");
    }

    this.registrations.set(contextId, context);
    return await this.updateRegistration(contextId, context);
  }

  public async removeNotificationsContext(contextId: string): Promise<void> {
    if (!this.registrations.has(contextId)) {
      return;
    }

    await this.deleteNotificationContext(contextId);
    if (this.transport.isConnected) {
      this.registrations.delete(contextId);
    }
  }
}

export { Registrations };
